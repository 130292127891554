import React from 'react';
import LoginIcon from '@mui/icons-material/Login';

interface ImpersonateProps {
  status?: string;
  userId?: string;
  setIsLoading?: (loading: boolean) => void;
}

const Impersonate: React.FC<ImpersonateProps> = ({ status, userId }) => {
  const impersonationIdExist = localStorage.getItem('impersonation-id');

  const handleImpersonate = e => {
    e.stopPropagation();
    if (!impersonationIdExist) {
      localStorage.setItem('impersonation-id', userId as string);
      location.href = '/';
    }
  };

  if (status === 'INVITE_SENT') {
    return <></>;
  }
  return (
    <LoginIcon
      sx={{
        cursor: impersonationIdExist ? 'not-allowed' : 'pointer',
        color: impersonationIdExist ? '#555' : '#000',
      }}
      onClick={handleImpersonate}
    />
  );
};

export default Impersonate;
