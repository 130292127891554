import { useMutation } from 'react-query';
import { useFetchMutation } from '../../../helpers/api';

type UsePostOcioUserArguments = {
  firstName: string;
  lastName: string;
  email: string;
  successToastMessage: string;
};

type UsePostOcioUserResult = {
  postOcioUserMutate: (data: UsePostOcioUserArguments) => void;
};

export const usePostOcioUser = (): UsePostOcioUserResult => {
  const { fetchMutation } = useFetchMutation();

  const { mutate } = useMutation((data: UsePostOcioUserArguments) =>
    fetchMutation({
      path: `/v2/ocios/user`,
      method: 'POST',
      body: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
      },
      invalidateQueries: [['ocio-user']],
      successToastMessage: data.successToastMessage,
    }),
  );

  return { postOcioUserMutate: mutate };
};
