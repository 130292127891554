import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import { useGetRecentPortfolioConstructions } from 'pages/Cases/hooks/useGetRecentPortfolioConstructions';
import useUrlParams from '../../../../helpers/hooks/useUrlParams';
import InputFilter from '@components/InputFilter';
import EmptyList from 'components/EmptyList';

const basePath = '/portfolio-constructions/recent';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Case',
    flex: 1,
    sortable: false,
  },
  {
    field: 'owners',
    headerName: 'Case owner',
    flex: 1,
    sortable: false,
    renderCell: params => (
      <div>
        {params.value.map((owner: string, index: number) => (
          <p key={index}>{owner}</p>
        ))}
      </div>
    ),
  },
  {
    field: 'versionName',
    headerName: 'Version',
    flex: 1,
    sortable: false,
  },
  {
    field: 'updatedAt',
    headerName: 'Last updated',
    flex: 1,
    sortable: false,
    valueGetter: updatedAt => moment(updatedAt).format('MM/DD/YYYY h:mm A'),
  },
];

const RecentPortfolioConstructions: React.FC = () => {
  const navigate = useNavigate();
  const { urlParams, updateUrl } = useUrlParams(basePath, { searchFilter: '' });
  const {
    recentPortfolioConstructions,
    isLoadingRecentPortfolioConstructions,
  } = useGetRecentPortfolioConstructions({
    searchFilter: urlParams.searchFilter,
  });

  const renderEmptyList = () => {
    if (
      recentPortfolioConstructions.length === 0 &&
      urlParams.searchFilter !== ''
    ) {
      return (
        <EmptyList
          loading={isLoadingRecentPortfolioConstructions}
          title="There are no results that match these filters"
          buttons={[
            {
              content: 'Clear all filters',
              handleClick: () => navigate(basePath),
            },
          ]}
        />
      );
    } else if (recentPortfolioConstructions.length === 0) {
      return (
        <EmptyList
          loading={isLoadingRecentPortfolioConstructions}
          title="It seems that there are no cases yet"
        />
      );
    }
  };

  const handleRowClick = ({ row }) =>
    navigate(
      `/cases/${row.id}/portfolio-constructions/${row.actionId}/summary`,
    );

  return (
    <Grid
      container
      wrap="wrap"
      rowGap={2}
      direction="column"
      padding={{ xs: '10px', sm: '20px' }}
      sx={{ background: '#fff', borderRadius: '4px' }}
    >
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        gap={1}
        flexGrow={1}
      >
        <Grid item flexGrow={1}>
          <InputFilter
            value={urlParams.searchFilter}
            placeholder="Search for Case name"
            debounceTime={500}
            handleInputChange={searchFilter => updateUrl({ searchFilter })}
          />
        </Grid>
      </Grid>

      <Grid item sx={{ width: '100%' }}>
        {renderEmptyList()}
        <div
          hidden={
            isLoadingRecentPortfolioConstructions ||
            recentPortfolioConstructions.length === 0
          }
        >
          <DataGrid
            disableColumnMenu
            columns={columns}
            rows={recentPortfolioConstructions}
            hideFooter={true}
            sortingMode="server"
            sx={{
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                {
                  outline: 'none',
                },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                {
                  outline: 'none',
                },
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
              },
            }}
            onRowClick={handleRowClick}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default RecentPortfolioConstructions;
