import React, { useState } from 'react';
import InputTicker from '../InputTicker';
import InputWeight from '../InputWeight';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

const TickerForm: React.FC<{
  handleAddSecurity: (id: string, name: string, weight: number) => void;
}> = ({ handleAddSecurity }) => {
  const [ticker, setTicker] = useState<string>('');
  const [weight, setWeight] = useState<number>(0);

  const handleClickAddButton = () => {
    const tickerParts = ticker.match(/\(([^)]+)\)/);
    const name = tickerParts?.length ? tickerParts[1] : '';
    const id =
      name === ''
        ? ''
        : ticker.replace('(', '').replace(')', '').replace(name, '').trim();
    if (id !== '' && name !== '' && weight > 0) {
      handleAddSecurity(id, name, weight / 100);
    }
  };

  return (
    <Stack spacing={2}>
      <InputTicker ticker={ticker} setTicker={setTicker} />
      <InputWeight setWeight={setWeight} />
      <Box>
        <Button
          variant="text"
          color="primary"
          sx={{
            backgroundColor: '#f1f1f1',
            borderRadius: '50vw',
            border: 'none',
            textTransform: 'none',
            paddingInline: 2,
          }}
          startIcon={<AddIcon />}
          onClick={handleClickAddButton}
        >
          Add Security
        </Button>
      </Box>
    </Stack>
  );
};

export default TickerForm;
