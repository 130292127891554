import { useMutation } from 'react-query';
import { useFetchMutation } from '../../../helpers/api';

type UseSendRiskAssessmentArguments = {
  advisorId: string;
  clientId: string;
  emailSubject: string;
  emailBody: string;
};

type UseSendRiskAssessmentResult = {
  isSendRiskAssessmentLoading: boolean;
  sendRiskAssessmentMutate: (body: any) => void;
};

export const useSendRiskAssessmentMutation =
  (): UseSendRiskAssessmentResult => {
    const { fetchMutation } = useFetchMutation();

    const { mutate, isLoading } = useMutation(
      (body: UseSendRiskAssessmentArguments) =>
        fetchMutation({
          path: `/riskAssessment/link`,
          method: 'POST',
          body,
        }),
    );

    return {
      isSendRiskAssessmentLoading: isLoading,
      sendRiskAssessmentMutate: mutate,
    };
  };
