import { useMutation } from 'react-query';
import { useFetchMutation } from '../../../helpers/api';

type AiModuleBasic = {
  module: string;
  tools: {
    tool: string;
    enable: boolean;
  }[];
};

type UsePutAiModulesArguments = {
  enterpriseId: string;
  modules: AiModuleBasic[];
  successToastMessage: string;
};

type UsePutAiModulesResult = {
  putAiModulesMutate: (data: UsePutAiModulesArguments) => void;
};

export const usePutAiModules = (): UsePutAiModulesResult => {
  const { fetchMutation } = useFetchMutation();

  const { mutate } = useMutation((data: UsePutAiModulesArguments) =>
    fetchMutation({
      path: `/v2/chatbotModules/${data.enterpriseId}`,
      method: 'PUT',
      body: data.modules,
      invalidateQueries: [['aiModules', data.enterpriseId]],
      successToastMessage: data.successToastMessage,
    }),
  );

  return { putAiModulesMutate: mutate };
};
