import React, { useEffect, useState } from 'react';
import { AiModule, useGetAiModulesById } from '../hooks/useGetAiModulesById';
import { usePutAiModules } from '../hooks/usePutAiModulesById';
import { Button, Checkbox, Grid } from '@mui/material';
import CircularProgressBar from 'components/CircularProgressBar';
import BackArrowIcon from '@icons/BackArrow';

type EnterpriseAiModulesProps = {
  enterpriseDetails: any;
  setStepper: (stepper: 'FIRM_LISTING_PAGE') => void;
};

function EnterpriseAiModules({
  enterpriseDetails,
  setStepper,
}: EnterpriseAiModulesProps): React.JSX.Element {
  const { putAiModulesMutate } = usePutAiModules();
  const { aiModules, isLoadingAiModules } = useGetAiModulesById({
    enterpriseId: enterpriseDetails.id,
  });

  const [uiModules, setUiModules] = useState<AiModule[]>([]);

  useEffect(() => {
    setUiModules([...aiModules]);
  }, [aiModules]);

  // event helpers

  const handleCheckboxChange = (
    module: string,
    tool: string,
    isChecked: boolean,
  ) => {
    const clickedModule = uiModules.find(item => item.module === module);
    const clickedTool = clickedModule!.tools.find(item => item.tool === tool);
    clickedTool!.enable = isChecked;
    setUiModules([...uiModules]);
  };

  const handleSubmit = ev => {
    ev.preventDefault();
    putAiModulesMutate({
      enterpriseId: enterpriseDetails.id,
      modules: uiModules,
      successToastMessage: 'The AI Modules have been updated',
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
      }}
    >
      <Grid container>
        {/* bread crumb */}
        <Grid
          item
          container
          xs={6}
          justifyContent="flex-start"
          alignItems={'center'}
          columnGap={1}
          style={{ cursor: 'pointer' }}
          onClick={() => setStepper('FIRM_LISTING_PAGE')}
        >
          <Grid item>
            <BackArrowIcon />
          </Grid>
          <Grid item>
            <p>All Enterprises</p>
          </Grid>
          <Grid item>/</Grid>
          <Grid item>
            <p>{enterpriseDetails?.name}</p>
          </Grid>
          <Grid item>/</Grid>
          <Grid item>AI Modules</Grid>
        </Grid>

        {/* save button */}
        <Grid container item xs={6} justifyContent="flex-end">
          <Button
            variant="outlined"
            color="primary"
            sx={{ textTransform: 'capitalize' }}
            onClick={handleSubmit}
          >
            Save Changes
          </Button>
        </Grid>
      </Grid>

      {isLoadingAiModules ? (
        <CircularProgressBar key="progressBar" />
      ) : (
        <Grid
          container
          direction="column"
          style={{
            marginTop: '20px',
            paddingRight: '10px',
            width: '100%',
            height: 'calc(100vh - 180px)',
            overflowY: 'scroll',
            backgroundColor: '#fff',
          }}
        >
          <table
            style={{
              border: '1px solid',
              borderCollapse: 'collapse',
            }}
          >
            {/* table header */}
            <thead
              style={{
                background: '#000',
                fontSize: '12px',
                color: '#fff',
              }}
            >
              <tr style={{ borderBottom: '1px solid #000' }}>
                <th
                  style={{
                    padding: '10px',
                    border: '1px solid #000',
                    minWidth: '100px',
                  }}
                >
                  Module
                </th>
                <th
                  style={{
                    border: '1px solid #000',
                    minWidth: '100px',
                  }}
                >
                  Tools
                </th>
                <th
                  style={{
                    border: '1px solid #000',
                    minWidth: '100px',
                  }}
                >
                  Enable
                </th>
              </tr>
            </thead>
            {/* table body */}
            <tbody style={{ fontSize: '12px' }}>
              {uiModules.map((module, moduleIndex) =>
                module.tools.map((tool, toolIndex) => (
                  <tr key={`${moduleIndex}-${toolIndex}`}>
                    {!toolIndex && (
                      <td
                        rowSpan={module.tools.length}
                        style={{
                          border: '1px solid grey',
                          padding: '10px',
                        }}
                      >
                        {module.name}
                        <p style={{ color: '#888' }}>{module.description}</p>
                      </td>
                    )}
                    <td
                      style={{
                        border: '1px solid grey',
                        padding: '10px',
                      }}
                    >
                      {tool.name}
                      <p style={{ color: '#888' }}>{tool.description}</p>
                    </td>
                    <td
                      style={{
                        borderBottom: '1px solid grey',
                        textAlign: 'center',
                      }}
                    >
                      <Checkbox
                        checked={tool.enable}
                        onChange={ev =>
                          handleCheckboxChange(
                            module.module,
                            tool.tool,
                            ev.target.checked,
                          )
                        }
                      />
                    </td>
                  </tr>
                )),
              )}
            </tbody>
          </table>
        </Grid>
      )}
    </div>
  );
}

export default EnterpriseAiModules;
