import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';
import { getHoldingCategoryOrder } from '../helpers/misc';

type UseGetCaseActionByIdArguments = {
  actionId: string;
  caseId: string;
};

type UseGetCaseActionByIdResult = {
  action: any | undefined;
  isActionLoading: boolean;
};

type UseGetCaseActionByIdResponse = {
  data: {
    id: string;
    case_id: string;
    user_id: string;
    milestone_id: string;
    version_name: string;
    created_at: string;
    updated_at: string;
    draft: boolean;
    value: any;
  };
};

export const useGetCaseActionById = ({
  actionId,
  caseId,
}: UseGetCaseActionByIdArguments): UseGetCaseActionByIdResult => {
  const { fetchQuery } = useFetchQuery();

  const { data, isLoading } = useQuery<UseGetCaseActionByIdResponse>(
    ['action', actionId],
    fetchQuery({
      path: `v2/cases/${caseId}/milestones/actions/${actionId}`,
    }),
    { enabled: !!actionId && !!caseId },
  );

  return {
    action: data?.data ? parseMilestoneAction(data.data) : undefined,
    isActionLoading: isLoading,
  };
};

const parseMilestoneAction = (action: any) => {
  if (!action.value) {
    return action;
  }
  return {
    ...action,
    model_set_holdings: action.value.model_set_holdings.map(
      modelSetHolding => ({
        ...modelSetHolding,
        portfolio_options: modelSetHolding.portfolio_options.map(
          portfolioOption => ({
            ...portfolioOption,
            portfolios: portfolioOption.portfolios.map(portfolio => ({
              ...portfolio,
              holdings: sortHoldings(portfolio.holdings),
            })),
          }),
        ),
      }),
    ),
  };
};

const sortHoldings = (holdings: any) => {
  return holdings.sort((holdingA, holdingB) => {
    const orderA = getHoldingCategoryOrder(holdingA.category_role);
    const orderB = getHoldingCategoryOrder(holdingB.category_role);
    return orderA === orderB
      ? holdingA.profile - holdingB.profile
      : orderA - orderB;
  });
};
