import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useRoles } from '../../helpers/hooks/useRoles';
import CustomModels from './Custom';
import CustomModelId from './Custom/Id';
import Marketplace from './Marketplace';
import MarketplaceModelId from './Marketplace/Id';
import TabsLayout from '../../components/TabsLayout/TabsLayout';

const ModelsPage: React.FC = () => {
  const { isOcioRole } = useRoles();

  const defaultPath = isOcioRole() ? 'marketplace' : 'custom-models';

  const tabs = isOcioRole()
    ? []
    : [
        {
          label: 'Custom Models',
          path: 'custom-models',
          validPatterns: ['/models/custom-models/:id'],
        },
      ];

  tabs.push(
    {
      label: 'Marketplace',
      path: 'marketplace',
      validPatterns: ['/models/marketplace/:id'],
    },
    {
      label: 'Favorites',
      path: 'favorites',
      validPatterns: ['/models/favorites'],
    },
  );

  return (
    <Routes>
      <Route element={<TabsLayout tabs={tabs} />}>
        <Route index element={<Navigate to={defaultPath} replace />} />
        <Route path="/custom-models" element={<CustomModels />} />
        <Route path="/custom-models/:id" element={<CustomModelId />} />
        <Route
          path="/marketplace"
          element={<Marketplace isFavorites={false} />}
        />
        <Route path="/marketplace/:id" element={<MarketplaceModelId />} />
        <Route path="/favorites" element={<Marketplace isFavorites={true} />} />
      </Route>
    </Routes>
  );
};

export default ModelsPage;
