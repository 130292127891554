import * as React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  CaseMilestone,
  CaseMilestoneAction,
} from 'pages/Cases/hooks/useGetCases';

type AccordionExpandIconProps = {
  milestone: CaseMilestone;
  index: number;
};

const AccordionExpandIcon: React.FC<AccordionExpandIconProps> = ({
  milestone,
  index,
}) => {
  return (
    <Accordion
      disableGutters
      sx={{
        backgroundColor: '#fff',
        boxShadow: 'none',
        border: 'none',
        '&.MuiAccordion-root::before': { display: 'none' },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          height: '60px',
          borderBottom: 'none',
        }}
      >
        <Box display="flex" alignItems="center" gap="8px" width="100%">
          {/*
           * We don't know when a milestone is complete and when it isn't.
           * When we know, we have to uncomment the next component.
           */}
          {/* <CompleteIconStatus>
            <CheckIcon sx={{ width: '18px', height: '18px' }} />
          </CompleteIconStatus> */}
          <InProgressStatus>{index + 1}</InProgressStatus>
          <Box flex={1}>
            <Typography sx={{ fontSize: '16px', color: '#282829' }}>
              {milestone.displayName}
            </Typography>
          </Box>
          <Typography
            sx={{
              paddingRight: '8px',
              fontSize: '12px',
              fontWeight: '400',
              color: '#888',
            }}
          >
            Created at {moment(milestone.createdAt).format('MM/DD/YYYY')}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Divider sx={{ margin: '0 0 8px 36px' }} />
        {milestone.actions.map((action, index) => (
          <Box
            key={index}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            m="4px 0 4px 36px"
          >
            <Typography
              sx={{
                paddingRight: '8px',
                fontSize: '14px',
                color: '#535256',
              }}
            >
              {action.versionName}
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                color: '#475467',
              }}
            >
              {moment(action.createdAt).format('MM/DD/YYYY')}
            </Typography>
            <Box flex={1} textAlign="right">
              <Link
                to={getActionPath(milestone.type, action)}
                style={{
                  display: 'inline-block',
                  textDecoration: 'none',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#0084E3',
                  }}
                >
                  View
                </Typography>
              </Link>
            </Box>
          </Box>
        ))}
        <Divider sx={{ margin: '8px 0 0 36px' }} />
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionExpandIcon;

const getActionPath = (milestoneType: string, action: CaseMilestoneAction) => {
  switch (milestoneType) {
    case 'portfolio_constructor':
    case 'portfolio_construction':
      return `/cases/${action.caseId}/portfolio-constructions/${action.id}/summary`;
    default:
      return `/cases`;
  }
};

// const CompleteIconStatus = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 28px;
//   height: 28px;
//   background-color: #1bc29a;
//   border-radius: 50%;
//   color: #fff;
// `;

const InProgressStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: #f5f5f5;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
  color: #282829;
`;
