import React, { createElement, useEffect, useState } from 'react';

import ListItems from '../ListItems';

import { Divider, Drawer, IconButton, List } from '@mui/material';

import ProfileMenuIcon from '@icons/ProfileMenuIcon';

interface DrawerCompProps {
  headerMenuList: any;
  profileMenuList: any;
  handleClose: () => void;
  handleLogout: () => void;
  impersonationId: string | null;
  handleRemoveImpersonation: () => void;
}

const DrawerComp: React.FC<DrawerCompProps> = ({
  headerMenuList,
  profileMenuList,
  handleClose,
  handleLogout,
  impersonationId,
  handleRemoveImpersonation,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const userType = localStorage.getItem('tifin-ai-user-type');

  const handleListItemClick = () => {
    setOpenDrawer(false);
  };

  const menuItemsProfile = ({ item }) => {
    return (
      <ListItems
        key={item.path}
        path={item.path}
        handleAction={() => {
          if (item.path === '/exit-impersonation') {
            handleRemoveImpersonation();
          } else if (item.path === '/logout') {
            handleLogout();
          } else {
            handleClose();
          }
        }}
        icon={createElement(item.Icon)}
        text={item.name}
      />
    );
  };

  useEffect(() => {
    handleClose();
  }, []);

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List sx={{ width: '250px' }}>
          {headerMenuList.map((item, index) => {
            if (item.showToUser.includes(userType)) {
              return (
                <ListItems
                  key={index}
                  path={item.path}
                  handleAction={handleListItemClick}
                  icon={createElement(item.Icon)}
                  text={item.name}
                />
              );
            }
          })}
        </List>
        <Divider />
        <List>
          {profileMenuList.map((item: any) =>
            impersonationId
              ? menuItemsProfile({ item })
              : item.path !== '/exit-impersonation' &&
                menuItemsProfile({ item }),
          )}
        </List>
      </Drawer>
      <IconButton
        sx={{ marginLeft: 'auto' }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <ProfileMenuIcon />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
