import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import PercentageField from 'components/PercentageField';

const InputWeight: React.FC<{
  setWeight: (weight: number) => void;
}> = ({ setWeight }) => {
  const handlePercentageChange = (value: number) => {
    setWeight(value);
  };

  return (
    <div>
      <InputLabel
        htmlFor="weight"
        sx={{ fontSize: 14, color: '#282829', marginBottom: '4px' }}
      >
        Weight as Percentage
      </InputLabel>
      <PercentageField
        name="weight"
        id="weight"
        placeholder="Weight"
        onPercentageChange={handlePercentageChange}
      />
    </div>
  );
};

export default InputWeight;
