import React from 'react';
import { Button } from '@mui/material';

interface ModalButtonProps {
  label: string;
  variant?: 'primary' | 'secondary';
  handleClick: () => void;
}

const ModalButton: React.FC<ModalButtonProps> = ({
  label,
  variant = 'primary',
  handleClick,
}) => {
  return variant === 'primary' ? (
    <Button
      variant="outlined"
      sx={{
        width: '100%',
        borderRadius: '20px',
        borderColor: '#96969B',
        backgroundColor: '#96969B',
        textTransform: 'capitalize',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#000',
        },
      }}
      onClick={handleClick}
    >
      {label}
    </Button>
  ) : (
    <Button
      variant="outlined"
      sx={{
        width: '100%',
        borderRadius: '20px',
        borderColor: '#ddd',
        textTransform: 'capitalize',
        color: '#000',
      }}
      onClick={handleClick}
    >
      {label}
    </Button>
  );
};

export default ModalButton;
