import React from 'react';
import { useParams } from 'react-router-dom';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { useGetCaseActionById } from 'pages/Cases/hooks/useGetCaseActionById';
import { useGetCaseById } from 'pages/Cases/hooks/useGetCaseById';
import BackButton from 'components/BackButton';
import GreyFrame from 'components/GrayFrame';
import SummaryHeader from 'pages/Cases/components/SummaryHeader';
import ModelSetsTable from 'pages/Cases/components/ModelSetsTable';

const ModelSets: React.FC = () => {
  const { actionId, caseId, modelSetName } = useParams();
  const { caseData } = useGetCaseById({ id: caseId! });
  const { action, isActionLoading } = useGetCaseActionById({
    actionId: actionId || '',
    caseId: caseId || '',
  });

  return (
    <Stack
      sx={{
        overflowY: 'auto',
        width: '100%',
        height: '100%',
        paddingBottom: '48px',
      }}
      direction="column"
    >
      <Grid container sx={{ padding: '16px' }}>
        <Grid item xs={12} sx={{ marginBottom: '16px' }}>
          <BackButton name="Summary" />
        </Grid>
        <GreyFrame>
          <Grid
            container
            item
            xs={12}
            sx={{ padding: '16px' }}
            alignItems="center"
          >
            <SummaryHeader caseData={caseData} action={action} />
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Typography
                gutterBottom
                sx={{ fontSize: '16px', fontWeight: '600' }}
              >
                {modelSetName}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          {!isActionLoading && <ModelSetsTable action={action} />}
        </GreyFrame>
      </Grid>
    </Stack>
  );
};

export default ModelSets;
