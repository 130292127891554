import React from 'react';
import {
  Grid,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';

interface SegmentData {
  name: string;
  value: number;
  color: string;
}

interface BarChartData {
  segments: SegmentData[];
  label: string;
}

interface BarChartProps {
  data: BarChartData[];
  option?: number;
}

const BarChart: React.FC<BarChartProps> = ({ data, option }) => {
  return (
    <Container style={{ border: '1px solid #dadde9', background: '#fff' }}>
      {data.map((bar, index) => (
        <Row key={index}>
          <Number>{bar.label}</Number>
          <ProgressBar>
            <TooltipStyled
              title={
                <>
                  <Typography color="#DEDEDF" paddingBottom={1.5}>
                    CLIENT RISK PROFILE {bar.label}
                  </Typography>
                  {bar.segments.map((seg, index) => (
                    <TooltipSegment key={index} seg={seg} />
                  ))}
                </>
              }
            >
              <div>
                {bar.segments.map((segment, segIndex) => (
                  <Segment
                    key={segIndex}
                    color={segment.color}
                    style={{
                      borderLeft: segIndex !== 0 ? '1px solid #fff' : 'none',
                      width: `${segment.value}%`,
                      left: `${segIndex > 0 ? bar.segments.slice(0, segIndex).reduce((acc, curr) => acc + curr.value, 0) : 0}%`,
                    }}
                  />
                ))}
              </div>
            </TooltipStyled>
          </ProgressBar>
        </Row>
      ))}
      <Footer sx={{ fontSize: 12, color: '#666' }}>Option {option}</Footer>
    </Container>
  );
};

export default BarChart;

const TooltipSegment = ({ seg }: any) => {
  return (
    <Grid item container key={seg.name} alignItems="center" paddingBottom={1}>
      <Grid item style={{ marginRight: 8 }}>
        <div
          style={{
            width: '15px',
            height: '15px',
            backgroundColor: seg.color,
            border: '1px solid #dadde9',
            display: 'inline-block',
          }}
        />
      </Grid>
      <Grid item>
        <b>{seg.name}</b>: {seg.value}% <br />
      </Grid>
    </Grid>
  );
};

const Container = styled('div')({
  width: '100%',
  padding: '12px',
  boxSizing: 'border-box',
});

const Row = styled('div')({
  display: 'block',
  marginBottom: 10,
  position: 'relative',
  height: 14,
});

const Number = styled(Typography)({
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  width: 24,
  color: '#757575',
  fontSize: 10,
});

const ProgressBar = styled('div')({
  position: 'absolute',
  left: 16,
  right: 0,
  height: '100%',
  background: '#e0e0e0',
  borderRadius: 9999,
  overflow: 'hidden',
});

const Segment = styled('div')<{ color: string }>(({ color }) => ({
  position: 'absolute',
  height: '100%',
  top: 0,
  cursor: 'help',
  backgroundColor: color,
}));

const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000',
    color: '#fff',
    width: '400px',
    padding: theme.spacing(2),
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const Footer = styled(Typography)({
  textAlign: 'center',
  color: '#757575',
  fontSize: 14,
  marginTop: 8,
});
