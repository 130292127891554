import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';

type UseGetIsNewUserResult = {
  isNewUserLogin: boolean;
};

type UseGetIsNewUserResponse = {
  data?: {
    data: {
      count: number;
    };
  };
};

export const useGetIsNewUser = (): UseGetIsNewUserResult => {
  const { fetchQuery } = useFetchQuery();

  const { data }: UseGetIsNewUserResponse = useQuery(
    'userSessionCount',
    fetchQuery({ path: '/v2/users/session/count' }),
  );

  return {
    isNewUserLogin: (data?.data.count || 0) < 3,
  };
};
