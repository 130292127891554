import { useState } from 'react';

const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max);
};

const toDecimal = (value: number) => {
  return Number((value / 100).toFixed(4));
};

const toPercentage = (value: number) => {
  return Number((value * 100).toFixed(2));
};

const isFiniteNumber = (value: number | string | undefined) =>
  Number.isFinite(value);

type useDecimalsReturn = {
  inputValue: string;
  handleInputValueChange: (newInputValue: string) => void;
  decimalValue: number;
  percentageValue: number;
};

const usePercentage = (
  initialDecimalValue: number | undefined,
): useDecimalsReturn => {
  const [inputValue, setInputValue] = useState<string>(
    isFiniteNumber(initialDecimalValue)
      ? toPercentage(initialDecimalValue as number).toString()
      : '',
  );
  const [decimalValue, setDecimalValue] = useState(
    isFiniteNumber(initialDecimalValue) ? (initialDecimalValue as number) : 0,
  );
  const [percentageValue, setPercentageValue] = useState(
    isFiniteNumber(initialDecimalValue)
      ? toPercentage(initialDecimalValue as number)
      : 0,
  );

  const handleInputValueChange = (newInputValue: string) => {
    const isWritingDecimal = newInputValue.endsWith('.');
    let parsedNewInputValue = parseFloat(newInputValue);

    if (!isNaN(parsedNewInputValue) && !isWritingDecimal) {
      parsedNewInputValue = clamp(parsedNewInputValue, 0, 100);
      setInputValue(parsedNewInputValue.toString());
      setDecimalValue(toDecimal(parsedNewInputValue));
      setPercentageValue(parsedNewInputValue);
    } else {
      setInputValue(newInputValue);
      setDecimalValue(0);
      setPercentageValue(0);
    }
  };

  return {
    inputValue,
    handleInputValueChange,
    decimalValue,
    percentageValue,
  };
};

export default usePercentage;
