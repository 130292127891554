import { useNavigate, useSearchParams } from 'react-router-dom';

type UseUrlParamsReturn = {
  urlParams: Record<string, any>;
  updateUrl: (newParams: Record<string, any>) => void;
};

const useUrlParams = (
  basePath: string,
  options: Record<string, any>,
): UseUrlParamsReturn => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const urlParams = Object.keys(options).reduce((acc, key) => {
    acc[key] = searchParams.get(key) || options[key];
    return acc;
  }, {});

  const updateUrl = (newParams: Record<string, any>) => {
    const newUrl = new URLSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      ...newParams,
    });
    navigate(`${basePath}?${newUrl}`);
  };

  return { urlParams, updateUrl };
};

export default useUrlParams;
