import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetClientDetails } from '../../../Clients/hooks/useGetClientDetails';
import { useGetAdvisorSettings } from 'pages/Settings/FirmProfile/hooks/useGetAdvisorSettings';
import { useSendRiskAssessmentMutation } from 'pages/Conversations/hooks/useSendRiskAssessment';
import { getAuthDetails } from '@features/Profile/selector';
import { actions } from '@features/Chat/slice';
import EmailEditor, {
  EmailBodyBlocksProps,
  EmailType,
} from 'components/EmailEditor';
import PrompQuestions from '@components/PrompQuestions';

/**
 * This component manages the editing and sending of the email.
 * - Editing is managed when the <ChatBot /> receives the message of type: risk_assessment_email_editor
 * - Sending is managed when the <ChatBot /> receives the message of type: send_assessment
 * That is, two different instances of this component must manage a single process.
 *
 * One possible action is to save the edited email in the top component <ChatBot /> but,
 * it may be the case that the user edits the email, leaves the chat, returns to the chat and sends the email.
 *
 * It could also be saved in a global context of the app, but this is information that only affects this component.
 *
 * The chosen solution is to save the last edit in the local variable editedEmail,
 * and if the <ChatBot /> orders to send the email, we check that the clientId is the same and we send it.
 */

const editedEmail = {
  subject: '',
  body: '',
  clientId: '',
};

interface RiskAssessmentEmailEditorProps {
  clientId: string | undefined;
  mode: 'edit' | 'send';
}

const RiskAssessmentEmailEditor: React.FC<RiskAssessmentEmailEditorProps> = ({
  clientId = '',
  mode = 'edit',
}) => {
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);
  const advisorDetails = useSelector(getAuthDetails);
  const { client, isClientLoading } = useGetClientDetails({ clientId });
  const { advisorSettingsData, advisorSettingsLoading } =
    useGetAdvisorSettings();
  const { isSendRiskAssessmentLoading, sendRiskAssessmentMutate } =
    useSendRiskAssessmentMutation();

  useEffect(() => {
    if (
      mode === 'send' &&
      clientId === editedEmail.clientId &&
      editedEmail.clientId !== '' &&
      editedEmail.subject !== '' &&
      editedEmail.body !== ''
    ) {
      sendRiskAssessmentMutate({
        advisorId: advisorDetails?.advisorId,
        clientId,
        emailSubject: editedEmail.subject,
        emailBody: editedEmail.body,
      });
      editedEmail.subject = '';
      editedEmail.body = '';
      editedEmail.clientId = '';
    }
  }, []);

  const isLoading = isClientLoading || advisorSettingsLoading;

  const emailDisclosure =
    advisorSettingsData?.emailDisclosure !== undefined
      ? advisorSettingsData?.emailDisclosure
      : '';

  const bodyBlocks: EmailBodyBlocksProps[] = [
    {
      type: 'html',
      value: `<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title></title>
</head>
<body style="margin: 0; padding: 0;">
<div style="max-width: 600px; font-family: sans-serif; font-size: 12px; font-weight: 500; color: #000;">
<div style="margin: auto">`,
    },
    {
      type: 'item',
      value: `<img src="${window.location.origin}/assets/images/email/banner-header.svg" alt="" style="width: 100%;" />`,
      styles: {
        padding: '0 0 20px 0',
      },
    },
    {
      type: 'html',
      value: `</div>
<header style="padding: 20px 0 0 20px; font-size: 20px; font-weight: 500; color: #000;">`,
    },
    {
      type: 'input',
      value: `Hello ${client?.firstName} ${client?.lastName},`,
      styles: {
        fontFamily: 'sans-serif',
        fontSize: '24px',
        fontWeight: '500',
        padding: '0 20px',
        height: 'auto',
        color: '#000',
      },
    },
    {
      type: 'html',
      value: `</header>
<div style="padding: 20px;">`,
    },
    {
      type: 'input',
      value: `We believe in the importance of personalizing advice to help our clients reach their financial, professional, and personal goals. To ensure we provide the highest level of service, please complete this short assessment:`,
      styles: {
        fontFamily: 'inter, sans-serif',
        fontSize: '12px',
        fontWeight: '400',
        padding: '5px 20px',
        color: '#000',
      },
    },
    {
      type: 'html',
      value: `</div>
<div style="padding: 0 0 0 20px;">`,
    },
    {
      type: 'item',
      value: `<a href="{{assessment-link}}" style="display: inline-block; text-decoration: none; color: #fff; background-color: black; padding: 6px 12px; border-radius: 30px;">
<img src="${window.location.origin}/assets/images/email/icon-logo.svg" style="vertical-align: middle" />
<span style="vertical-align: middle; font-size: 16px">Take Assessment</span>
<img src="${window.location.origin}/assets/images/email/icon-right-arrow.svg" style="vertical-align: middle; margin-right: 4px" />
</a>`,
      styles: {
        padding: '10px 20px',
      },
    },
    {
      type: 'html',
      value: `</div>
<div style="padding: 20px;">`,
    },
    {
      type: 'input',
      value: `The assessment helps us better understand your financial situation and create a personalized investment plan tailored to your needs. We look forward to discussing your results with you during our next meeting.

If you have any questions, email us at support@tifinsage.ai`,
      styles: {
        fontFamily: 'inter, sans-serif',
        fontSize: '12px',
        fontWeight: '400',
        padding: '5px 20px',
        color: '#000',
      },
    },
    {
      type: 'html',
      value: `</div>
<div style="padding: 0 20px; 20px; 20px;">`,
    },
    {
      type: 'input',
      value: `Best,
${advisorDetails?.firstName} ${advisorDetails?.lastName}
${advisorDetails?.advisoryFirmName}
${advisorDetails?.email}`,
      styles: {
        fontFamily: 'inter, sans-serif',
        fontSize: '12px',
        fontWeight: '400',
        padding: '5px 20px',
        color: '#000',
      },
    },
    {
      type: 'html',
      value: `</div>`,
    },
    {
      type: 'item',
      value: `<hr style="margin: 20px 20px 5px 20px; border: none; border-top: 1px solid #eee;" />`,
    },
    {
      type: 'html',
      value: `<div style="padding: 5px 20px; 20px; 20px; font-size: 10px; color: #969696">`,
    },
    {
      type: 'input',
      value: emailDisclosure,
      styles: {
        fontFamily: 'inter, sans-serif',
        fontSize: '10px',
        fontWeight: '400',
        margin: '0 0 20px 0',
        padding: '5px 20px',
        color: '#969696',
      },
    },
    {
      type: 'html',
      value: `</div></div></body></html>`,
    },
  ];

  // event handlers

  const handleSave = (email: EmailType) => {
    if (mode === 'edit') {
      // save the email in the local variable
      editedEmail.subject = email.subject;
      editedEmail.body = email.body;
      editedEmail.clientId = clientId;
    }
  };

  const handleSend = () => {
    if (mode === 'edit') {
      // response "yes" to the chatbot
      const isChatStreaming = localStorage.getItem('streaming') === 'true';
      dispatch(
        isChatStreaming
          ? actions.setSearchQuery('Yes')
          : actions.searchQueryRequest({ query: 'Yes' }),
      );
    }
  };

  return mode === 'edit' && clientId && !isLoading ? (
    <>
      <PrompQuestions
        showDividers
        itemPy="10px"
        questions={[
          `Edit risk assesment email for ${client?.firstName} ${client?.lastName}`,
        ]}
        handleQuestionClick={() => setIsDrawerOpen(true)}
      />

      <EmailEditor
        isDrawerOpen={isDrawerOpen}
        title="Edit email template"
        subtitle={`Risk Assesment questionnaire for ${client?.firstName} ${client?.lastName} - ${client?.email}`}
        subject="Risk assessment link"
        bodyBlocks={bodyBlocks}
        isLoading={isLoading}
        isSending={isSendRiskAssessmentLoading}
        handleEmailSave={handleSave}
        handleEmailSend={handleSend}
        handleCloseDrawer={() => setIsDrawerOpen(false)}
      />
    </>
  ) : null;
};

export default RiskAssessmentEmailEditor;
