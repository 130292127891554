import { useMutation } from 'react-query';
import { useFetchMutation } from '../../../helpers/api';

type UsePutCaseArguments = {
  id: string;
  status: string;
  successToastMessage: string;
};

type UsePutCaseResult = {
  putCaseMutate: (data: UsePutCaseArguments) => void;
};

export const usePutCase = (): UsePutCaseResult => {
  const { fetchMutation } = useFetchMutation();

  const { mutate } = useMutation((data: UsePutCaseArguments) =>
    fetchMutation({
      path: `/v2/ocios/cases/${data.id}`,
      method: 'PUT',
      body: { status: data.status },
      invalidateQueries: [['cases']],
      successToastMessage: data.successToastMessage,
    }),
  );

  return { putCaseMutate: mutate };
};
