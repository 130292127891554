import React, { useState } from 'react';
import {
  DataGrid,
  type GridColDef,
  type GridValueFormatter,
  type GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid';
import { Typography, Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
// import { usePutActionsDetails } from 'pages/Construction/hooks/usePutActionsDetails';
import { CaseMilestoneAction } from 'pages/Cases/hooks/useGetCases';
import {
  getHoldingCategoryId,
  getModelSetIndexByName,
  holdingsCategories,
} from 'pages/Cases/helpers/misc';
import { useParams } from 'react-router-dom';
import PrimaryButton from 'components/PrimaryButton';
import SecondaryButton from 'components/SecondaryButton';

// props

type ModelSetsTableProps = {
  action: CaseMilestoneAction;
};

type ModelSetTableRow = {
  id: number | string;
  strategist: string;
  profile: number | null;
  [key: string]: number | string | null;
};

const getColumnWidth = (field: string) => {
  if (field === 'strategist') {
    return { minWidth: 200, flex: 1 };
  } else if (field === 'profile') {
    return { minWidth: 70, flex: 0.3 };
  } else {
    return { minWidth: 60, flex: 0.4 };
  }
};

// herlper methods

const getModelSet = (action: any, modelSetIndex: number) =>
  action?.value?.model_set_holdings[modelSetIndex];

const parseModelSetToRows = (modelSet: any): ModelSetTableRow[] => {
  const rows: ModelSetTableRow[] = [];
  let id = 1;
  modelSet?.portfolio_options.forEach((option: any) => {
    option.portfolios.forEach((portfolio: any) => {
      portfolio.holdings.forEach((holding: any) => {
        let existingRow = rows.find(row => row.strategist === holding.strategy);
        if (!existingRow) {
          existingRow = {
            id: id++,
            strategist: holding.strategy,
            categoryClassName: `category-${getHoldingCategoryId(holding.category_role)}`,
            profile: holding.profile,
          };
          rows.push(existingRow);
        }
        const optionPrefix = `option${option.option}`;
        const profileIndex = portfolio.risk_profile;
        if (profileIndex >= 1 && profileIndex <= 6) {
          existingRow[`${optionPrefix}_${profileIndex}`] = holding.weight * 100;
        }
      });
    });
  });
  return rows;
};

const getColumnTotal = (rows: ModelSetTableRow[], key: string) =>
  rows.reduce((sum, row) => {
    const value = row[key];
    return sum + (typeof value === 'number' ? value : 0);
  }, 0);

const getTotalsRow = (rows: ModelSetTableRow[]) => {
  const totalRow: ModelSetTableRow = {
    id: 'totals',
    strategist: '',
    profile: null,
  };
  if (rows.length > 0) {
    // ToDo: por qué este [0]
    Object.keys(rows[0]).forEach(key => {
      if (key !== 'id' && key !== 'strategist' && key !== 'profile') {
        totalRow[key] = getColumnTotal(rows, key);
      }
    });
  }
  return totalRow;
};

const getColumnGroupingModel = (modelSet: any, modelSetName: string) =>
  modelSet?.portfolio_options.map((option: any) => ({
    groupId: `Option${option.option}`,
    headerName: `${modelSetName}. Option ${option.option}`,
    description: 'Client Risk Profile',
    headerAlign: 'center',
    renderHeaderGroup: HeaderGroup,
    children: Array.from({ length: 6 }, (_, i) => i + 1).map(profile => ({
      field: `option${option.option}_${profile}`,
      headerName: `${profile}`,
      renderHeader: () => profile.toString(),
    })),
  }));

const getColumns = (rows: ModelSetTableRow[]) => {
  if (rows.length === 0) {
    return [];
  }

  const baseColumns: GridColDef[] = [
    {
      field: 'strategist',
      headerName: 'Strategist',
      ...getColumnWidth('strategist'),
      sortable: false,
      headerClassName: 'base-columns-header',
      editable: false,
    },
    {
      field: 'profile',
      headerName: 'Profile',
      ...getColumnWidth('profile'),
      sortable: false,
      headerClassName: 'base-columns-header',
      headerAlign: 'center' as const,
      align: 'center' as const,
      editable: false,
      type: 'number' as const,
    },
  ];

  const optionColumns = Object.keys(rows[0])
    .filter(key => key.startsWith('option'))
    .map(field => ({
      field,
      headerName: field.split('_')[1],
      ...getColumnWidth(field),
      sortable: false,
      headerAlign: 'center' as const,
      align: 'center' as const,
      editable: true,
      type: 'number' as const,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomEditCell {...params} />
      ),
      valueFormatter: (params: GridValueFormatter) =>
        params !== null ? `${params}%` : '',
      preProcessEditCellProps: (params: any) => {
        const hasError = params.props.value < 0 || params.props.value > 100;
        return { ...params.props, error: hasError };
      },
    }));

  return [...baseColumns, ...optionColumns];
};

const hasRowChanged = (newRow: any, oldRow: any) =>
  Object.keys(newRow).some(key => newRow[key] !== oldRow[key]);

const ensureRowDecimals = (row: any) => {
  Object.keys(row).forEach(key => {
    if (key.startsWith('option')) {
      row[key] = row[key] ? Math.round(Number(row[key]) * 100) / 100 : null;
    }
  });
  return row;
};

const isValidForm = (rows: ModelSetTableRow[]) => {
  const totalRow = getTotalsRow(rows);
  return Object.keys(totalRow).every(key =>
    key.startsWith('option') ? totalRow[key] === 100 : true,
  );
};

const getCellClassName = (params: any) => {
  let className = '';
  if (params.id === 'totals') {
    className = ' total-row';
    const value = params.row[params.field];
    if (Number(value) && value !== 100) {
      className += ' total-error';
    }
  } else {
    if (params.field.startsWith('option')) {
      className += ' input-cell';
    }
    className += ` ${params.row.categoryClassName}`;
  }
  return className;
};

// helpers components

const CustomEditCell = (props: GridRenderEditCellParams) => {
  const { id, value, field } = props;
  const api = useGridApiContext();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <input
        type="number"
        value={value.toFixed(2) ?? ''}
        onChange={event => {
          const newValue =
            event.target.value === '' ? null : Number(event.target.value);
          api.current.setEditCellValue({ id, field, value: newValue });
        }}
        style={{
          width: '55px',
          padding: '7px 4px 5px 4px',
          border: '1px solid #ddd',
          borderRadius: '6px',
          backgroundColor: '#fff',
          textAlign: 'center',
          fontSize: '14px',
          fontWeight: 'bold',
          outline: 'none',
        }}
      />
    </Box>
  );
};

const HeaderGroup = (params: any) => (
  <Box sx={{ textAlign: 'center', width: '100%' }}>
    <Typography
      variant="subtitle2"
      component="div"
      sx={{
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {params.headerName}
    </Typography>
    <Typography variant="caption" component="div">
      {params.description}
    </Typography>
  </Box>
);

// main component

const ModelSetsTable = ({ action }: ModelSetsTableProps): JSX.Element => {
  const { modelSetName = '' } = useParams();
  const modelSetIndex = getModelSetIndexByName(modelSetName, action);
  const modelSet = getModelSet(action, modelSetIndex);
  const parsedRows = parseModelSetToRows(modelSet);
  const [rows, setRows] = useState<ModelSetTableRow[]>(parsedRows);
  const [savedRows, setSavedRows] = useState<ModelSetTableRow[]>(parsedRows);
  const [hasChanges, setHasChanges] = useState(false);
  // const { putActionsDetailsMutate } = usePutActionsDetails();

  const handleSaveChanges = () => {
    alert('Saving changes (WIP)');
    setSavedRows([...rows]);
    // const updatedPortfolioData = {
    //   ...portfolioData,
    //   value: {
    //     ...portfolioData.value,
    //     model_set_holdings: [
    //       {
    //         ...portfolioData.value.model_set_holdings[modelSetIndex],
    //         portfolio_options: portfolioData.value.model_set_holdings[
    //           modelSetIndex
    //         ].portfolio_options.map((option: any) => ({
    //           ...option,
    //           portfolios: option.portfolios.map((portfolio: any) => ({
    //             ...portfolio,
    //             holdings: portfolio.holdings.map((holding: any) => {
    //               const row = rows.find(r => r.strategist === holding.strategy);
    //               const weight = row
    //                 ? row[`option${option.option}_${portfolio.risk_profile}`]
    //                 : null;
    //               return {
    //                 ...holding,
    //                 weight:
    //                   weight !== null ? Number(weight) / 100 : holding.weight,
    //               };
    //             }),
    //           })),
    //         })),
    //       },
    //     ],
    //   },
    // };
    // putActionsDetailsMutate({
    //   milestoneId: 'fd07d985-a944-43d7-9571-5aa00a936cee',
    //   actionId: '8f3d5b67-d2c8-4893-a05b-baeffba6e372',
    //   body: updatedPortfolioData,
    //   successToastMessage: 'Changes saved successfully',
    // });
    setHasChanges(false);
  };

  const handleDiscardChanges = () => {
    setRows([...savedRows]);
    setHasChanges(false);
  };

  return (
    <Grid item xs={12} sx={{ padding: '16px' }}>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        <Typography sx={{ fontWeight: 600 }}>Model Sets</Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <SecondaryButton
            text="Discard changes"
            heightVariant="small"
            disabled={!hasChanges}
            handleClick={handleDiscardChanges}
          />
          <PrimaryButton
            text="Save changes"
            heightVariant="small"
            disabled={!hasChanges || !isValidForm(rows)}
            handleClick={handleSaveChanges}
          />
        </Box>
      </Grid>
      <Box sx={{ width: '100%' }}>
        <StyledDataGrid
          rows={[...rows, getTotalsRow(rows)]}
          columns={getColumns(rows)}
          columnGroupingModel={getColumnGroupingModel(modelSet, modelSetName)}
          disableColumnMenu
          disableColumnResize
          disableRowSelectionOnClick
          hideFooter
          getCellClassName={getCellClassName}
          rowHeight={36}
          processRowUpdate={(newRow, oldRow) => {
            const updatedRow = { ...ensureRowDecimals(newRow) };
            if (hasRowChanged(newRow, oldRow)) {
              const updatedRows = rows.map(row =>
                row.id === newRow.id ? updatedRow : row,
              ) as ModelSetTableRow[];
              setRows(updatedRows);
              setHasChanges(true);
            }
            return updatedRow;
          }}
          onProcessRowUpdateError={error => {
            console.error('Error updating row:', error);
          }}
        />
      </Box>
    </Grid>
  );
};

export default ModelSetsTable;

const StyledDataGrid = styled(DataGrid)(({ theme }) => {
  return {
    ...getCategoryClassNames(),
    borderTop: 'none',
    borderRight: 'none',
    '& *': {
      outline: 'none!important',
      cursor: 'default!important',
    },
    '& svg': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      borderBottom: 'none!important',
    },
    '& .MuiDataGrid-columnHeader': {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderRight: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.paper,
      '&[data-field^="option"]': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    '& .MuiDataGrid-cell': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    '& .MuiDataGrid-cell--editable': {
      boxShadow: 'none!important',
      '&:hover': {
        backgroundColor: `${theme.palette.background.paper}!important`,
        fontWeight: 'bold',
        cursor: 'text!important',
      },
    },
    '& .total-row': {
      backgroundColor: '#f5f5f5 !important',
      fontWeight: 'bold',
      pointerEvents: 'none',
      '&.total-error': {
        color: '#f00 !important',
      },
    },
    '& .total-row[data-colindex="0"]': {
      borderRight: 'none!important',
    },
    '& input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button':
      {
        WebkitAppearance: 'none',
        margin: 0,
      },
    '& input[type="number"]': {
      MozAppearance: 'textfield',
    },
  };
});

const getCategoryClassNames = () =>
  holdingsCategories.reduce((css, category) => {
    css[`& .category-${category.id}`] = {
      backgroundColor: `${category.color} !important;`,
    };
    return css;
  }, {});
