import React, { useState } from 'react';
import PlusIcon from '@icons/plusIcon';
import WhitePlus from '@icons/whitePlus';
import { Grid } from '@mui/material';
import AddEnterpriseModal from './AddEnterpriseModal';
import EntCard from './EntCard';
import {
  SearchBoxInput,
  CustomButton,
  CustomSoloEntCardWrapper,
  CustomSpan,
} from './styles';
import EnterpriseSuccessModal from './EnterpriseSuccessModal';

export interface EnterpriseListingProps {
  enterpriseData: any;
  setStepper: React.Dispatch<
    React.SetStateAction<
      | 'ENTERPRISE_LISTING_PAGE'
      | 'FIRM_LISTING_PAGE'
      | 'FIRM_DETAILS_PAGE'
      | 'ENTERPRISE_AI_MODULES'
    >
  >;
  stepper:
    | 'ENTERPRISE_LISTING_PAGE'
    | 'FIRM_LISTING_PAGE'
    | 'FIRM_DETAILS_PAGE'
    | 'ENTERPRISE_AI_MODULES';
  setEnterpriseDetails: React.Dispatch<React.SetStateAction<any>>;
}

const EnterpriseListingPage = ({
  enterpriseData,
  setEnterpriseDetails,
  setStepper,
}: EnterpriseListingProps): React.JSX.Element => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [logoFile, setLogoFile] = useState<File[]>([]);
  const [enterpriseStepper, setEnterpriseStepper] = useState<
    'ADD_ENTERPRISE' | 'SUCCESS_SCREEN'
  >('ADD_ENTERPRISE');

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleConfirmUpload = () => {
    if (logoFile) {
      // later implement the logic to actually upload the file.
      setModalOpen(false);
    } else {
      console.error('Please upload a file before confirming.');
    }
  };

  const handleSuccessAndClose = () => {
    setModalOpen(false);
  };

  const handleCardClick = item => {
    setEnterpriseDetails(item);
    setStepper('FIRM_LISTING_PAGE');
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = () =>
    Array.isArray(enterpriseData) && enterpriseData?.length > 0
      ? enterpriseData.filter(enterprise =>
          enterprise.name.toLowerCase().includes(searchTerm.toLowerCase()),
        )
      : [];

  return (
    <Grid container item xs={12}>
      <Grid
        item
        container
        xs={11.8}
        direction={'row'}
        justifyContent={'space-between'}
      >
        <Grid item container xs={6} direction={'column'}>
          <Grid
            item
            container
            justifyContent={'flex-start'}
            color={'#1d2939'}
            fontSize={'18px'}
            fontWeight={500}
          >
            Enterprise
          </Grid>
          <Grid
            item
            container
            color={'#667085'}
            fontSize={'13px'}
            fontWeight={400}
            pt={'5px'}
          >
            Select an enterprise to view the firms and members within them
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={6}
          alignItems={'center'}
          columnGap={2}
          justifyContent={'flex-end'}
        >
          <Grid item>
            <SearchBoxInput
              type="text"
              placeholder="Search Enterprise"
              value={searchTerm}
              onChange={handleSearch}
            />
          </Grid>
          <Grid
            item
            container
            xs={3.5}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <CustomButton variant="outlined" onClick={handleOpenModal}>
              <CustomSpan item justifyContent={'center'} alignItems={'center'}>
                <WhitePlus />
              </CustomSpan>
              Add Enterprise
            </CustomButton>
          </Grid>

          {/* Modal */}
          <AddEnterpriseModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            handleUpload={handleConfirmUpload}
            setEnterpriseStepper={setEnterpriseStepper}
            logoFile={logoFile}
            setLogoFile={setLogoFile}
            handleSuccessAndClose={handleSuccessAndClose}
          />

          {/* Success Modal */}
          <EnterpriseSuccessModal
            isOpen={enterpriseStepper === 'SUCCESS_SCREEN'}
            onClose={() => setEnterpriseStepper('ADD_ENTERPRISE')}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sx={{
          maxHeight: '100%',
          overflowY: 'auto',
        }}
      >
        <Grid
          item
          container
          xs={12}
          pt={2.5}
          rowGap={2}
          columnGap={2}
          sx={{
            cursor: 'pointer',
            flexWrap: 'wrap',
          }}
        >
          {filteredData() &&
            filteredData().length > 0 &&
            filteredData()?.map((item, index) => (
              <EntCard
                key={index}
                name={item?.name}
                firmNumber={item?.totalFirms}
                totalMembers={item?.totalAdvisors}
                handleClick={() => handleCardClick(item)}
              />
            ))}
          <CustomSoloEntCardWrapper onClick={handleOpenModal}>
            <Grid
              item
              container
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                width: '50px',
                height: '50px',
                cursor: 'pointer',
                background: '#e4e7ec',
                borderRadius: '50%',
              }}
            >
              <Grid
                item
                container
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  width: '36px',
                  height: '36px',
                  background: '#98a2b3',
                  borderRadius: '50%',
                }}
              >
                <PlusIcon />
              </Grid>
            </Grid>
            <Grid item color={'#1d2939'} fontSize={'14px'} pt={'15px'}>
              Add Enterprise
            </Grid>
          </CustomSoloEntCardWrapper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EnterpriseListingPage;
