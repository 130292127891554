import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TabsLayout from 'components/TabsLayout/TabsLayout';
import RecentPortfolioConstructions from './RecentPortfolioConstructions';

const constructionTabs = [
  {
    label: 'Recent',
    path: 'recent',
  },
];

const ConstructionPage: React.FC = () => {
  return (
    <Routes>
      <Route element={<TabsLayout tabs={constructionTabs} />}>
        <Route index element={<Navigate to="recent" replace />} />
        <Route path="recent" element={<RecentPortfolioConstructions />} />
      </Route>
    </Routes>
  );
};

export default ConstructionPage;
