import React from 'react';
import { Button } from '@mui/material';

type SecondaryButtonProps = {
  text: string;
  heightVariant?: 'small' | 'medium';
  disabled?: boolean;
  handleClick?: () => void;
};

const SecondaryButton = ({
  text,
  heightVariant = 'medium',
  disabled = false,
  handleClick,
}: SecondaryButtonProps): React.ReactNode => {
  return (
    <Button
      variant="contained"
      sx={{
        height: heightVariant === 'small' ? '32px' : '36px',
        borderRadius: '20px',
        background: '#ddd',
        textTransform: 'none',
        color: '#000',
        boxShadow: 'none',
        fontSize: heightVariant === 'small' ? '12px' : '14px',
        '&:hover': {
          backgroundColor: '#000',
          color: '#fff',
          boxShadow: 'none',
        },
        '&:disabled': {
          background: '#ddd',
          color: '#888',
          boxShadow: 'none',
        },
      }}
      disabled={disabled}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
};

export default SecondaryButton;
