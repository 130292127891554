import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DrawerComp from './components/Drawer';
import MenuProfile from './components/MenuProfile';
import {
  AppBar,
  Box,
  Grid,
  Tab,
  Tabs,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import Logo from '@images/LogoSvg.svg';
import { useRoles } from '../../helpers/hooks/useRoles';
import useLogout from '../../helpers/hooks/useLogout';

const NavBar: React.FC = () => {
  const location = useLocation();
  const { getMainMenuItems, getProfileMenuItems } = useRoles();
  const headerMenuList = getMainMenuItems();
  const profileMenuList = getProfileMenuItems();
  const userType = localStorage.getItem('tifin-ai-user-type');
  const impersonationId = localStorage.getItem('impersonation-id');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { handleLogout, handleRemoveImpersonation } = useLogout();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentTabPath = useMemo(() => {
    return headerMenuList.find(tab => {
      const currentPath = (location.pathname || '').split('/')[1];
      const tabPath = tab.path.split('/')[1];
      return currentPath === tabPath;
    })?.path;
  }, [location.pathname, headerMenuList]);

  const tabsItems = useMemo(() => {
    return headerMenuList.map(tab => ({ ...tab, value: tab.path }));
  }, [headerMenuList]);

  const selectedTabsCount = useMemo(() => {
    return tabsItems.filter(item => item.showToUser.includes(userType || ''))
      .length;
  }, [tabsItems, userType]);

  const breakpoint = useMemo(() => {
    return tabsItems.reduce((acc, item) => {
      return item.showToUser.includes(userType || '')
        ? selectedTabsCount > 4
          ? acc + 170
          : acc + 240
        : acc;
    }, 0);
  }, [tabsItems, userType]);

  const showMobileMenu = useMediaQuery(
    `@media (max-width:${breakpoint + 80}px)`,
  );

  return (
    <Box sx={{ width: '100%', height: '80px' }}>
      <AppBar
        sx={{
          background: '#ffffff',
          boxShadow: '0 3px 10px rgb(0 0 0 / 0.1)',
          color: 'black',
          padding: '0 36px',
        }}
      >
        <Toolbar sx={{ height: '80px' }}>
          <Grid container justifyContent={'start'} alignItems={'center'}>
            <Grid item xs={2}>
              <Box component={Link} to="/conversations">
                <img src={Logo} style={{ transform: 'scale(1.3)' }} />
              </Box>
            </Grid>
            {showMobileMenu ? (
              <DrawerComp
                handleLogout={handleLogout}
                handleClose={handleClose}
                headerMenuList={headerMenuList}
                profileMenuList={profileMenuList}
                impersonationId={impersonationId}
                handleRemoveImpersonation={handleRemoveImpersonation}
              />
            ) : (
              <Grid
                container
                item
                xs={10}
                justifyContent={'end'}
                alignItems={'center'}
              >
                <Tabs
                  indicatorColor="primary"
                  textColor="inherit"
                  value={currentTabPath || false}
                >
                  {tabsItems.map(item => {
                    if (userType && item.showToUser.includes(userType)) {
                      return (
                        <Tab
                          component={Link}
                          to={item.path}
                          key={item.path}
                          value={item.path}
                          icon={
                            item.Icon &&
                            (typeof item.Icon === 'string' ? (
                              <img
                                src={item.Icon}
                                alt={item.name}
                                style={{ marginRight: '10px' }}
                              />
                            ) : (
                              <item.Icon />
                            ))
                          }
                          style={{
                            textTransform: 'none',
                            maxWidth: '170px',
                            height: '80px',
                          }}
                          iconPosition="start"
                          label={item.name}
                        />
                      );
                    }
                  })}
                </Tabs>
                <MenuProfile
                  profileMenuList={profileMenuList}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  handleClose={handleClose}
                  handleLogout={handleLogout}
                  handleRemoveImpersonation={handleRemoveImpersonation}
                  impersonationId={impersonationId}
                />
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavBar;
