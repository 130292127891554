import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';

export type OcioUser = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

type UseGetOcioUsersResult = {
  ocioUsers: OcioUser[];
  isLoadingOcioUsers: boolean;
};

type UseGetOcioUsersResponse = {
  data: OcioUser[];
  message: string;
};

export const useGetOcioUsers = (): UseGetOcioUsersResult => {
  const { fetchQuery } = useFetchQuery();

  const { data, isLoading } = useQuery<UseGetOcioUsersResponse>(
    ['ocioUsers'],
    fetchQuery({ path: `v2/ocios/users` }),
  );

  return {
    ocioUsers: data?.data || [],
    isLoadingOcioUsers: isLoading,
  };
};
