import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import {
  Case,
  CasesOrder,
  CasesOrderBy,
  useGetCases,
} from '../hooks/useGetCases';
import useUrlParams from '../../../helpers/hooks/useUrlParams';
import InputFilter from '@components/InputFilter';
import StatusFilter from '@components/StatusFilter';
import EmptyList from 'components/EmptyList';
import StatusChip from '../components/Chip';
import CaseDetail from '../CaseDetail';
import AddCaseModal from '../components/AddCaseModal';

// table columns

const renderOwnerCell = params => {
  return (
    <Box
      component="ul"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      margin="0"
      padding="0"
    >
      {params.value.map((owner, index) => (
        <li
          key={owner.id + index}
          style={{ lineHeight: 'normal', listStyle: 'none' }}
        >{`${owner.firstName} ${owner.lastName}`}</li>
      ))}
    </Box>
  );
};

const renderStatusCell = params => {
  return <StatusChip value={params.value} />;
};

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Case',
    flex: 2,
    sortable: false,
  },
  {
    field: 'owners',
    headerName: 'Owners',
    flex: 1,
    sortable: false,
    renderCell: renderOwnerCell,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    sortable: false,
    renderCell: renderStatusCell,
  },
  { field: 'tier', headerName: 'Tier', flex: 1, sortable: false },
  { field: 'createdAt', headerName: 'Created at', flex: 1, sortable: true },
  { field: 'updatedAt', headerName: 'Last updated', flex: 1, sortable: true },
  { field: 'stage', headerName: 'Stage', flex: 2, sortable: false },
];

// component

type AllCasesProps = {
  myCasesFilter?: boolean;
};

const pageSize = 50;

const AllCases: React.FC<AllCasesProps> = ({ myCasesFilter = false }) => {
  const navigate = useNavigate();
  const { urlParams, updateUrl } = useUrlParams(
    `/cases/${myCasesFilter ? 'my-cases' : 'all-cases'}`,
    {
      page: 1,
      order: 'desc',
      orderBy: 'createdAt',
      searchFilter: '',
      statusFilter: '',
      caseId: '',
    },
  );

  const [isOpenAddCase, setIsOpenAddCase] = useState(false);
  const {
    cases,
    casesCurrentPage,
    casesStatusCount,
    casesTotalCount,
    isLoadingCases,
  } = useGetCases({
    myCasesFilter: myCasesFilter,
    pageSize,
    currentPage: urlParams.page,
    order: urlParams.order as CasesOrder,
    orderBy: urlParams.orderBy as CasesOrderBy,
    searchFilter: urlParams.searchFilter,
    statusFilter: urlParams.statusFilter,
  });

  // render handlers

  const renderEmptyList = () => {
    if (cases.length === 0 && urlParams.searchFilter !== '') {
      return (
        <EmptyList
          loading={isLoadingCases}
          title="There are no results that match these filters"
          buttons={[
            {
              content: 'Clear all filters',
              handleClick: () =>
                navigate(`/cases/${myCasesFilter ? 'my-cases' : 'all-cases'}`),
            },
          ]}
        />
      );
    } else if (cases.length === 0) {
      return (
        <EmptyList
          loading={isLoadingCases}
          title="It seems that there are no cases yet"
          buttons={[
            {
              content: (
                <>
                  <AddIcon fontSize="small" /> Add Case
                </>
              ),
              handleClick: () => setIsOpenAddCase(true),
            },
          ]}
        />
      );
    }
  };

  // event handlers

  const handleSortModelChange = model => {
    if (model.length === 0) {
      updateUrl({
        page: 1,
        order: urlParams.order === 'desc' ? 'asc' : ('desc' as CasesOrder),
      });
    } else {
      updateUrl({
        page: 1,
        order: model[0].sort as CasesOrder,
        orderBy: model[0].field as CasesOrderBy,
      });
    }
  };

  const handleRowClick = ({ row }) => updateUrl({ caseId: row.id });

  return (
    <Grid
      container
      direction="column"
      wrap="wrap"
      rowGap={2}
      padding={{ xs: '10px', sm: '20px' }}
      sx={{ background: '#fff', borderRadius: '4px' }}
    >
      <Grid container justifyContent="flex-start" alignItems="center" gap={1}>
        {/* status filter */}
        <Grid item>
          <StatusFilter
            currentStatus={urlParams.statusFilter}
            statuses={[
              {
                title: 'All Statuses',
                value: '',
              },
              {
                title: 'In progress',
                value: 'inProgress',
                badgeColor: '#7E19FE',
                badgeNumber: casesStatusCount.inProgress,
              },
              {
                title: 'Completed',
                value: 'complete',
                badgeColor: '#03D990',
                badgeNumber: casesStatusCount.complete,
              },
            ]}
            handleStatusChange={statusFilter =>
              updateUrl({ page: 1, statusFilter })
            }
          />
        </Grid>

        {/* search filter */}
        <Grid item flexGrow={1}>
          <InputFilter
            value={urlParams.searchFilter}
            placeholder="Search for case name"
            debounceTime={500}
            handleInputChange={searchFilter =>
              updateUrl({ page: 1, searchFilter })
            }
          />
        </Grid>

        {/* add case button */}
        <Grid item>
          <Grid container sx={{ justifyContent: 'end' }}>
            <Button
              variant="outlined"
              style={{
                borderRadius: '25px',
                textTransform: 'none',
                height: 32,
                background: '#F1F1F1',
                border: 'none',
              }}
              color="primary"
              onClick={() => setIsOpenAddCase(true)}
            >
              <AddIcon fontSize="small" />
              Add case
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <AddCaseModal
        open={isOpenAddCase}
        handleClose={() => setIsOpenAddCase(false)}
      />
      {/* table */}
      <Grid item sx={{ width: '100%' }}>
        {renderEmptyList()}

        <div hidden={isLoadingCases || cases.length === 0}>
          <DataGrid
            disableColumnMenu
            columns={columns}
            // rows
            rows={parseCasesToRows(cases)}
            rowCount={casesTotalCount}
            onRowClick={handleRowClick}
            // pagination
            pageSizeOptions={[pageSize]}
            paginationMode="server"
            paginationModel={{ pageSize: pageSize, page: casesCurrentPage - 1 }}
            onPaginationModelChange={paginationModel => {
              updateUrl({ page: paginationModel.page + 1 });
            }}
            // sorting
            sortingMode="server"
            sortModel={[
              { field: urlParams.orderBy, sort: urlParams.order as CasesOrder },
            ]}
            onSortModelChange={handleSortModelChange}
            // styles
            sx={{
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                {
                  outline: 'none',
                },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                {
                  outline: 'none',
                },
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
              },
              '& .MuiDataGrid-selectedRowCount': {
                display: 'none',
              },
            }}
          />
        </div>
      </Grid>
      <CaseDetail onClose={() => updateUrl({ caseId: '' })} />
    </Grid>
  );
};

export default AllCases;

// stage helpers

const parseStage = (stage: string) => {
  switch (stage) {
    case 'initial_consult_form':
      return 'Initial consult form';
    default:
      return stage;
  }
};

// tier helpers

export const tierOptions = [
  { key: 'standard', label: 'Standard' },
  { key: 'standard_plus', label: 'Standard Plus' },
  { key: 'premium', label: 'Premium' },
];

export const parseTier = (tier: string): string => {
  return tierOptions.find(t => t.key === tier)?.label || tier;
};

// rows helpers

const parseCasesToRows = (cases: Case[]) =>
  cases?.map(({ createdAt, stage, tier, updatedAt, ...rest }) => ({
    ...rest,
    createdAt: moment(createdAt).format('MM/DD/YYYY'),
    stage: parseStage(stage),
    tier: parseTier(tier),
    updatedAt: moment(updatedAt).format('MM/DD/YYYY'),
  }));
