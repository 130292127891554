import { useState } from 'react';
import { useMutation } from 'react-query';
import { useFetchMutation } from '../../../helpers/api';

type UsePostProposalArguments = {
  modelId?: string;
  clientId: string;
  contentList: any;
};

type UsePostProposalResult = {
  proposalId: string;
  isProposalLoading: boolean;
  postProposalMutate: (body: any) => void;
};

type UsePostProposalReturn = {
  data: string;
};

export const usePostProposal = (): UsePostProposalResult => {
  const { fetchMutation } = useFetchMutation();
  const [proposalId, setProposalId] = useState<string>('');

  const { mutate, isLoading } = useMutation(
    ({ modelId, clientId, contentList }: UsePostProposalArguments) =>
      fetchMutation({
        path: `/client/proposal`,
        method: 'POST',
        body: modelId
          ? { modelId, clientId, contentList }
          : { clientId, contentList },
      }),
    {
      onSuccess: (res: UsePostProposalReturn) => setProposalId(res.data),
    },
  );

  return {
    proposalId,
    isProposalLoading: isLoading,
    postProposalMutate: mutate,
  };
};
