import React from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { Drawer, IconButton, Grid } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { makeStyles, styled } from '@mui/styles';
import { usePutCase } from '../hooks/usePutCase';
import { useGetCaseById } from '../hooks/useGetCaseById';
import { parseTier } from '../AllCases';
import MilestoneAccordion from '../components/MilestoneAccordion';
import StatusChip from '../components/Chip';
import CircularProgressBar from 'components/CircularProgressBar';

const useStyles = makeStyles(() => ({
  paper: {
    width: 720,
    backgroundColor: '#fff!important',
  },
}));

interface DetailCaseProps {
  onClose: () => void;
}

const DetailCase: React.FC<DetailCaseProps> = ({ onClose }) => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId')!;
  const { putCaseMutate } = usePutCase();
  const { caseData, isCaseLoading } = useGetCaseById({ id: caseId! });
  const handleStatusChange = (status: string) => {
    putCaseMutate({
      id: caseId,
      status,
      successToastMessage: 'Case status updated successfully',
    });
  };

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={!!caseId}
      classes={{ paper: classes.paper }}
    >
      <Layout container>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          pb="24px"
        >
          <LayoutTitle>Case details</LayoutTitle>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>

        {/* header */}
        {!isCaseLoading && (
          <>
            <Grid item container flexDirection="column" pb="16px">
              <Grid item pb={1}>
                <Name>{caseData?.name || ''}</Name>
              </Grid>
              <Grid item>
                <Description>{caseData?.description || ''}</Description>
              </Grid>
            </Grid>
            <Grid item container justifyContent="space-between" pb="24px">
              <Grid item>
                <FeatureLabel>Status</FeatureLabel>
                <StatusChip
                  isEditable
                  value={caseData?.status || ''}
                  handleStatusChange={handleStatusChange}
                />
              </Grid>
              <Grid item>
                <FeatureLabel>Advisor</FeatureLabel>
                <FeatureValue>{caseData?.advisorName || ''}</FeatureValue>
              </Grid>
              <Grid item>
                <FeatureLabel>Tier</FeatureLabel>
                <FeatureValue>{parseTier(caseData?.tier || '')}</FeatureValue>
              </Grid>
              <Grid item>
                <FeatureLabel>Last Update</FeatureLabel>
                <FeatureValue>
                  {moment(caseData?.updatedAt).format('MM/DD/YYYY h:mm A')}
                </FeatureValue>
              </Grid>
            </Grid>

            <Grid
              container
              pb="24px"
              direction="column"
              sx={{
                gap: '8px',
                backgroundColor: '#F5F5F5',
                padding: '16px',
                borderRadius: '4px',
              }}
            >
              <AccordionTitle>Case Status</AccordionTitle>
              {caseData?.milestones?.map((milestone, index) => (
                <MilestoneAccordion
                  key={index}
                  milestone={milestone}
                  index={index}
                />
              ))}
            </Grid>
          </>
        )}

        {isCaseLoading ? <CircularProgressBar size={50} /> : null}
      </Layout>
    </Drawer>
  );
};

export default DetailCase;

const Layout = styled(Grid)({
  flexDirection: 'column',
  padding: '32px',
  width: '100%',
});

const LayoutTitle = styled('p')({
  fontSize: '16px',
  lineHeight: '20px',
  fontFamily: 'inter',
  margin: '0',
  color: '#86858B',
});

const Name = styled('p')({
  fontSize: '16px',
  fontFamily: 'inter',
  fontWeight: 500,
  color: '#000',
});

const Description = styled('p')({
  fontSize: '14px',
  fontFamily: 'inter',
  color: '#535256',
});

const FeatureLabel = styled('p')({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '15px',
  fontFamily: 'inter',
  margin: '0 0 4px',
  color: '#282829',
});

const FeatureValue = styled('p')({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  fontFamily: 'inter',
  margin: '4px 0',
  color: '#000000',
});

const AccordionTitle = styled('p')({
  paddingBottom: '10px',
  fontSize: '16px',
  fontFamily: 'inter',
  color: '#000',
});
