import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';
import { useGetCaseActionById } from 'pages/Cases/hooks/useGetCaseActionById';
import { useGetCaseById } from 'pages/Cases/hooks/useGetCaseById';
import { getHoldingCategoryColor } from 'pages/Cases/helpers/misc';
import BarChart from '../../components/BarChart';
import BackButton from 'components/BackButton';
import GreyFrame from 'components/GrayFrame';
import SummaryHeader from 'pages/Cases/components/SummaryHeader';

const Summary: React.FC = () => {
  const { actionId, caseId } = useParams();
  const { caseData } = useGetCaseById({ id: caseId! });
  const { action } = useGetCaseActionById({
    actionId: actionId || '',
    caseId: caseId || '',
  });

  const modelSetHoldings =
    parseModelSetHoldings(action?.value?.model_set_holdings) || [];

  const handleSaveAsNewVersion = () => {};

  return (
    <Stack
      sx={{
        overflowY: 'auto',
        width: '100%',
        height: '100%',
        paddingBottom: '48px',
      }}
      direction="column"
    >
      <Grid container item sx={{ padding: '16px' }}>
        <Grid item xs={12} sx={{ marginBottom: '16px' }}>
          <BackButton name="Portfolio Construction" />
        </Grid>
        <GreyFrame>
          <Grid
            container
            item
            xs={12}
            sx={{ padding: '16px' }}
            alignItems="center"
          >
            <SummaryHeader caseData={caseData} action={action} />
            {/* <Grid
              item
              xs={2}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <PrimaryButton
                text="Save as new version"
                handleClick={handleSaveAsNewVersion}
              />
            </Grid> */}
          </Grid>
          <Divider />
          <List>
            {modelSetHoldings.map((modelSetHolding, index) => (
              <ModelSetHolding
                key={index}
                modelSetHolding={modelSetHolding}
                handleSaveAsNewVersion={handleSaveAsNewVersion}
              />
            ))}
          </List>
        </GreyFrame>
      </Grid>
    </Stack>
  );
};

export default Summary;

// render helpers

const ModelSetHolding = ({ modelSetHolding }: any) => {
  const navigate = useNavigate();
  const { actionId, caseId } = useParams();

  return (
    <ListItem>
      <Card
        sx={{
          width: '100%',
          padding: '0 16px 16px 16px',
          background: '#fff',
          border: '1px solid #E4E7EC',
          boxShadow: 'none',
          transition: 'background-color 0.3s',
          cursor: 'pointer',
          '&:hover': { backgroundColor: '#f8f8f8' },
        }}
        onClick={() => {
          navigate(
            `/cases/${caseId}/portfolio-constructions/${actionId}/model-sets/${modelSetHolding.name}`,
          );
        }}
      >
        <Grid
          container
          item
          spacing={2}
          alignItems="center"
          md={10}
          xs={12}
          margin="0 auto"
        >
          <ModelSetHoldingHeader modelSetHolding={modelSetHolding} />
          <Carrousel portfolioOptions={modelSetHolding.portfolio_options} />
        </Grid>
      </Card>
    </ListItem>
  );
};

const ModelSetHoldingHeader = ({ modelSetHolding }: any) => {
  return (
    <Grid item width="250px">
      <Typography
        gutterBottom
        sx={{ color: '#4F4F4F', fontSize: 18, fontWeight: 600 }}
      >
        {modelSetHolding.name}
      </Typography>
      <Typography sx={{ color: '#0084E3', fontSize: 12, fontWeight: 500 }}>
        View Details
      </Typography>
    </Grid>
  );
};

const Carrousel = ({ portfolioOptions }: any) => {
  const [currentOption, setCurrentOption] = useState(0);

  const handleArrowClick = (direction: number) => {
    const newOption = Math.min(
      Math.max(0, currentOption + direction),
      portfolioOptions.length - 1,
    );
    setCurrentOption(newOption);
  };

  return (
    <Grid
      id="carrousel"
      item
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 'calc(100% - 250px)',
        padding: '0',
      }}
      onClick={ev => ev.stopPropagation()}
    >
      <IconButton
        disabled={currentOption === 0}
        onClick={() => handleArrowClick(-1)}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <CarrouselItem portfolioOption={portfolioOptions[currentOption]} />
      <IconButton
        disabled={currentOption === portfolioOptions.length - 1}
        onClick={() => handleArrowClick(+1)}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </Grid>
  );
};

const CarrouselItem = ({ portfolioOption }: any) => {
  const chartData = portfolioOption.portfolios.map((portfolio: any) => ({
    label: portfolio.risk_profile,
    segments: portfolio.holdings.map((holding: any) => ({
      name: holding.name,
      value: holding.weight * 100,
      color: getHoldingCategoryColor(holding.category_role),
    })),
  }));
  return <BarChart data={chartData} option={portfolioOption.option} />;
};

// data helpers

const parseModelSetHoldings = (modelSetHoldings: any) => {
  return modelSetHoldings?.map((modelSetHolding: any) => ({
    name: modelSetHolding.model_set_name,
    portfolio_options: parsePortfolioOptions(modelSetHolding.portfolio_options),
  }));
};

const parsePortfolioOptions = (portfolioOptions: any) => {
  return portfolioOptions.map((portfolioOption: any) => ({
    option: portfolioOption.option,
    portfolios: parsePortfolios(portfolioOption.portfolios),
  }));
};

const parsePortfolios = (portfolios: any) => {
  return portfolios.map((portfolio: any) => ({
    risk_profile: portfolio.risk_profile,
    holdings: parseHoldings(portfolio, portfolio.holdings),
  }));
};

const parseHoldings = (portfolio: any, holdings: any) => {
  return holdings.map((holding: any) => ({
    weight: holding.weight,
    name: holding.full_model_name,
    category_role: holding.category_role,
    risk_profile: portfolio.risk_profile,
  }));
};
