import { settings } from 'config';
import { delay, http, HttpResponse } from 'msw';

function success() {
  const data = {
    message: 'Enterprise questions retrieved successfully',
    data: [
      {
        name: 'Client adjustments',
        module: 'client_adjustments',
        description: 'lorem ipsum',
        tools: [
          {
            name: 'Impact based adjustments',
            description: 'lorem ipsum',
            tool: 'impact_based_adjustments',
            enable: true,
          },
          {
            name: 'Portfolio adjustments',
            description: 'lorem ipsum',
            tool: 'portfolio_adjustments',
            enable: false,
          },
        ],
      },
      {
        name: 'Client Communications',
        module: 'client_communications',
        description: 'lorem ipsum',
        tools: [
          {
            name: 'Generate proposal for client',
            description: 'lorem ipsum',
            tool: 'generate_proposal_for_client',
            enable: true,
          },
        ],
      },
    ],
  };

  const status = 200;
  return HttpResponse.json(data, { status });
}

export const getEnterpriseAiModules = http.get(
  `${settings.api.baseUrl}/v2/modules/:enterpriseId`,
  async () => {
    await delay(1000);
    return success();
  },
);
