import React from 'react';
import { Box } from '@mui/material';

interface GreyFrameProps {
  children: React.ReactNode;
}

const GreyFrame: React.FC<GreyFrameProps> = ({ children }) => {
  return (
    <Box
      sx={{
        marginBottom: '24px',
        padding: '16px',
        width: '100%',
        background: '#f5f5f5',
      }}
    >
      <Box
        sx={{
          boxSizing: 'border-box',
          width: '100%',
          background: '#fff',
          borderRadius: '4px',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default GreyFrame;
