import React, { Dispatch, SetStateAction, useState } from 'react';
import WhitePlus from '@icons/whitePlus';
import { FIRM_CARD_OVERVIEW_DATA } from '@common/constant';
import AddFirmModal from './AddFirmModal';
import EnterpriseSection from './EnterpriseSection';
import FirmSection from './FirmSection';
import { Grid } from '@mui/material';
import BackArrowIcon from '@icons/BackArrow';
import { useRESTQuery } from '@hooks/useRESTQuery';
import {
  CustomButton,
  CustomSpan,
  FirmDetailsWrapper,
  ScrollableContainer,
  SearchBoxInput,
} from './styles';
import { handleError } from '@common/error';
import { useDispatch } from 'react-redux';
import Loader from '@components/Loader';

interface FirmListingPageProps {
  setStepper: Dispatch<
    SetStateAction<
      | 'ENTERPRISE_LISTING_PAGE'
      | 'FIRM_LISTING_PAGE'
      | 'FIRM_DETAILS_PAGE'
      | 'ENTERPRISE_AI_MODULES'
    >
  >;
  enterpriseDetails: any;
  setFirmDetails: Dispatch<any>;
}

const FirmListingPage = ({
  setStepper,
  enterpriseDetails,
  setFirmDetails,
}: FirmListingPageProps): React.JSX.Element => {
  const dispatch = useDispatch();
  const [searchResult, setSearchResult] = useState('');
  const [activeView, setActiveView] = useState('grid');
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleView = (view: string) => {
    setActiveView(view);
  };

  const handleFirmCardClick = item => {
    setFirmDetails(item);
    setStepper('FIRM_DETAILS_PAGE');
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSuccessAndClose = () => {
    setModalOpen(false);
  };

  const { data: allFirmList, isLoading } = useRESTQuery(
    ['get-all-advisor-firm'],
    {
      endpoint: `/firms/`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      select: (response: any) => {
        return { data: response?.data };
      },
      onError: (error: any) => {
        console.log(error);
      },
    },
  );

  const { data: enterpriseStatsData, isLoading: enterpriseLoading } =
    useRESTQuery(
      ['enterprise-details'],
      {
        endpoint: `/enterprise/id/?id=${enterpriseDetails?.id}`,
        method: 'GET',
        headers: {
          'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
        },
      },
      {
        select: res => res.data,
        onError: (error: any) => {
          handleError(dispatch, error);
        },
      },
    );

  if (isLoading || enterpriseLoading) return <Loader />;

  return (
    <>
      <Grid
        container
        item
        xs={11.8}
        direction={'column'}
        height={'110%'}
        columnGap={2}
      >
        <Grid item container justifyContent={'space-between'}>
          <Grid
            item
            container
            justifyContent={'flex-start'}
            alignItems={'center'}
            columnGap={1}
            onClick={() => setStepper('ENTERPRISE_LISTING_PAGE')}
            xs={4}
            style={{ cursor: 'pointer' }}
          >
            <Grid item>
              <BackArrowIcon />
            </Grid>
            <Grid item>
              <p>All Enterprises</p>
            </Grid>
            <Grid item>/</Grid>
            <Grid item>
              <p>{enterpriseDetails?.name}</p>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={6}
            justifyContent={'flex-end'}
            columnGap={2}
            alignItems={'center'}
          >
            <Grid item>
              <SearchBoxInput
                type="text"
                placeholder="Search"
                value={searchResult}
                onChange={e => setSearchResult(e.target.value)}
              />
            </Grid>
            <Grid item>
              <CustomButton variant="outlined" onClick={handleOpenModal}>
                <CustomSpan>
                  <WhitePlus />
                </CustomSpan>
                Add Firm
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
        <ScrollableContainer item container xs={12}>
          <FirmDetailsWrapper item container xs={12}>
            <EnterpriseSection
              data={enterpriseStatsData}
              enterpriseDetails={enterpriseDetails}
              setStepper={setStepper}
            />

            <FirmSection
              activeView={activeView}
              toggleView={toggleView}
              handleFirmCardClick={handleFirmCardClick}
              FIRM_CARD_OVERVIEW_DATA={FIRM_CARD_OVERVIEW_DATA}
              allFirmList={allFirmList}
            />
          </FirmDetailsWrapper>
        </ScrollableContainer>
      </Grid>
      <Grid item container alignItems={'center'}>
        <AddFirmModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          enterpriseDetails={enterpriseDetails}
          handleSuccessAndClose={handleSuccessAndClose}
        />
      </Grid>
    </>
  );
};

export default FirmListingPage;
