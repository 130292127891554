import React, { useEffect, useState } from 'react';
import { Button, Grid, IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import EditPencilIcon from '@icons/EditPencilIcon';
import DeleteIcon from '@icons/delete';
import { LoadingButton } from '@mui/lab';

import { AddClientsBtnText } from '@pages/ClientsV2/styles';

import CustomModelsTable from '../../Custom/components/CustomModelsTable';
import InputFilter from '@components/InputFilter';
import EmptyList from '../../../../components/EmptyList';

import {
  useGetCustomModelsQuery,
  GetCustomModelsParams,
} from '../../Custom/hooks/useGetCustomModelsQuery';
import { useDeleteCustomModelsMutation } from '../../Custom/hooks/useDeleteCustomModelsMutation';
import CustomModelUpload from '../CustomModelUpload';

interface CustomModelListProps {
  handleClickEditModel: (id: string) => void;
}

const CustomModelList: React.FC<CustomModelListProps> = ({
  handleClickEditModel,
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const [openModelDialog, setOpenModelDialog] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const [queryParams, setQueryParams] = useState<GetCustomModelsParams>({
    page: paginationModel.page + 1,
    limit: paginationModel.pageSize,
  });

  const { deleteCustomModelMutate } = useDeleteCustomModelsMutation();

  const { customModels, pagination, isCustomModelsLoading, refetch } =
    useGetCustomModelsQuery(queryParams);

  useEffect(() => {
    setQueryParams(prev => ({ ...prev, search: searchText }));
  }, [searchText]);

  // event helpers

  const handleCloseModel = () => setOpenModelDialog(false);
  const handleClickOpenModel = () => setOpenModelDialog(true);
  const handleSuccesRefetch = () => refetch();

  // render helpers

  const ActionButtons = data => {
    return (
      <Grid
        container
        wrap="wrap"
        rowGap={2}
        direction="column"
        color={'#000000'}
        padding={{ xs: '10px', sm: '20px' }}
        sx={{ borderRadius: '4px' }}
      >
        <Grid container item xs={12} justifyContent={'center'}>
          <Grid item width="20px">
            <IconButton
              style={{
                textTransform: 'none',
                background: 'transparent',
                border: 'none',
                padding: 0,
                pointerEvents: 'auto',
              }}
              onClick={ev => {
                ev.stopPropagation();
                handleClickEditModel(data.params.id);
              }}
            >
              <EditPencilIcon width="16px" height="16px" />
            </IconButton>
          </Grid>
          <Grid item width="20px">
            <LoadingButton
              style={{
                textTransform: 'none',
                background: 'transparent',
                border: 'none',
                padding: 0,
                color: '#000000',
                pointerEvents: 'auto',
              }}
              id={data.params.id}
              loading={data.params.id === currentItemId}
              loadingPosition="start"
              startIcon={<DeleteIcon width="18px" height="18px" />}
              variant="outlined"
              onClick={ev => {
                ev.stopPropagation();
                setCurrentItemId(data.params.id);
                deleteCustomModelMutate({
                  modelId: data.params.id,
                  successToastMessage: `The ${data.params.row.name} model has been successfully deleted`,
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderEmptyList = () => {
    if (customModels.length === 0 && searchText !== '') {
      return (
        <EmptyList
          loading={isCustomModelsLoading}
          title="There are no results that match these filters"
          buttons={[
            {
              content: 'Clear all filters',
              handleClick: () => setSearchText(''),
            },
          ]}
        />
      );
    } else if (customModels.length === 0) {
      return (
        <EmptyList
          loading={isCustomModelsLoading}
          title="It seems that there are no custom models yet"
          buttons={[
            {
              content: (
                <>
                  <AddIcon fontSize="small" /> Add Custom Model
                </>
              ),
              handleClick: handleClickOpenModel,
            },
          ]}
        />
      );
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Model name',
      display: 'flex',
      flex: 3,
      editable: false,
    },
    {
      field: 'updatedAt',
      type: 'date',
      headerName: 'Updated',
      display: 'flex',
      flex: 2,
      editable: false,
      renderCell: params => (
        <div>{moment(params.value).format('MM/DD/YYYY')}</div>
      ),
    },
    {
      field: 'securities',
      headerName: 'Securities',
      display: 'flex',
      flex: 2,
      editable: false,
      sortable: false,
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      hideSortIcons: true,
      display: 'flex',
      flex: 0,
      renderCell: params => <ActionButtons params={params} />,
    },
  ];

  return (
    <Grid
      container
      wrap="wrap"
      rowGap={2}
      direction="column"
      padding={{ xs: '10px', sm: '20px' }}
      sx={{ background: '#fff', borderRadius: '4px' }}
    >
      <Grid container justifyContent="flex-start" alignItems="center" gap={1}>
        <Grid item flexGrow={1}>
          <InputFilter
            value={searchText}
            placeholder="Search for Model name"
            debounceTime={500}
            handleInputChange={setSearchText}
          />
        </Grid>

        <Grid item>
          <Grid container sx={{ justifyContent: 'end' }}>
            <Button
              variant="outlined"
              style={{
                borderRadius: '25px',
                textTransform: 'none',
                height: 32,
                background: '#F1F1F1',
                border: 'none',
              }}
              color="primary"
              onClick={handleClickOpenModel}
            >
              <AddIcon fontSize="small" />
              &nbsp;<AddClientsBtnText>Add Custom Model</AddClientsBtnText>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={{ width: '100%' }}>
        {renderEmptyList()}

        {/* We hide the table so that when it appears again it retains the previous state, that is, it retains the column order, pagination... */}
        <div hidden={isCustomModelsLoading || customModels.length === 0}>
          <CustomModelsTable
            rows={customModels || []}
            columns={columns}
            handleRowClick={handleClickEditModel}
            getRowId={row => row.id}
            sortModel={
              queryParams.orderBy
                ? [{ field: queryParams.orderBy, sort: queryParams.order }]
                : []
            }
            onSortModelChange={newSortModel => {
              if (newSortModel.length === 0) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { orderBy, order, ...restParams } = queryParams;
                setQueryParams(restParams);
                return;
              }
              setQueryParams({
                ...queryParams,
                orderBy: newSortModel[0].field as 'name' | 'updatedAt',
                order: newSortModel[0].sort as 'asc' | 'desc',
              });
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={newPaginationModel => {
              setPaginationModel({
                page: newPaginationModel.page,
                pageSize: newPaginationModel.pageSize,
              });

              setQueryParams({
                ...queryParams,
                page: newPaginationModel.page + 1,
                limit: newPaginationModel.pageSize,
              });
            }}
            paginationMeta={{
              rowCount: pagination.totalCount,
              totalPages: pagination.totalPages,
              currentPage: pagination.currentPage,
              hasNextPage:
                pagination.currentPage < pagination.totalPages &&
                pagination.totalCount > 0,
            }}
          />
        </div>
      </Grid>
      {
        <CustomModelUpload
          handleCloseModel={handleCloseModel}
          openModelDialog={openModelDialog}
          handleSuccesRefetch={handleSuccesRefetch}
        />
      }
    </Grid>
  );
};

export default CustomModelList;
