import React from 'react';
import { Grid, Button, IconButton } from '@mui/material';
import PdfViewer from '@components/PdfViewer';
import MemoFile from '@icons/File';
import { validArray } from '@common/validArray';
import DialogModal from '@components/DialogModal';
import { SourceDocumentWrapper } from '../styles';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fileUrlToFileName } from '../../../../helpers/file';
import { splitArray } from '../../../../helpers/array';

function DocumentItem({
  document,
  index,
  handleOnClick,
}: {
  document: any;
  index: number;
  handleOnClick: () => void;
}): JSX.Element {
  return (
    <Grid
      item
      container
      className="card"
      xs={12}
      md={3.5}
      rowGap={1}
      key={index}
      onClick={handleOnClick}
    >
      <Grid item xs={12}>
        <p className="documentName">{document?.name}</p>
      </Grid>
      <Grid item container columnGap={1}>
        <Grid item xs={1}>
          <MemoFile />
        </Grid>
        <Grid item xs={10}>
          <p className="fileUrl">{fileUrlToFileName(document?.fileUrl)}</p>
        </Grid>
      </Grid>
    </Grid>
  );
}

function SourceDocument({
  data,
  isLastItem,
}: {
  data: any[];
  isLastItem: boolean;
  key: string;
}): JSX.Element {
  const [showSource, setShowSource] = React.useState(false);
  const [document, setDocument] = React.useState<any>({});
  const [showAllDocuments, setShowAllDocuments] = React.useState(false);
  const [visibleData, hiddenData] = splitArray(data, 3); // maximum documents shown at start

  const handleCloseSource = () => {
    setShowSource(false);
  };

  const handleClickDocument = (document: any) => {
    setDocument(document);
    isLastItem && setShowSource(true);
  };

  return (
    <>
      <SourceDocumentWrapper container columnGap={1} rowGap={1}>
        {validArray(visibleData) &&
          visibleData.map((document: any, index: number) => (
            <DocumentItem
              key={index}
              document={document}
              index={index}
              handleOnClick={() => handleClickDocument(document)}
            />
          ))}
        {validArray(hiddenData) &&
          hiddenData.length > 0 &&
          !showAllDocuments && (
            <Grid item xs={12}>
              <Button
                variant="text"
                sx={{ backgroundColor: 'rgb(241, 241, 241)' }}
                endIcon={<ExpandMoreIcon />}
                onClick={() => setShowAllDocuments(true)}
              >
                See more ({hiddenData.length})
              </Button>
            </Grid>
          )}
        {showAllDocuments &&
          hiddenData.map((document: any, index: number) => (
            <DocumentItem
              key={index + visibleData.length}
              document={document}
              index={index + visibleData.length}
              handleOnClick={() => handleClickDocument(document)}
            />
          ))}
      </SourceDocumentWrapper>

      {showSource && (
        <DialogModal
          openModal={showSource}
          handleClose={handleCloseSource}
          fullWidth={true}
          maxWidth={'lg'}
          closeIcon={
            <IconButton
              aria-label="close"
              onClick={handleCloseSource}
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                color: theme => theme.palette.grey[500],
                zIndex: 1,
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        >
          <PdfViewer
            fileUrl={document?.fileUrl}
            source={document?.sources}
            disableHighLight={true}
          />
        </DialogModal>
      )}
    </>
  );
}

export default SourceDocument;
