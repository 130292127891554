import React, { useEffect } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import usePercentage from 'helpers/hooks/usePercentage';

/**
 * PercentageField
 * This component is a wrapper around the TextField component.
 * It uses the usePercentage hook to handle the decimal value and the input value.
 * @example
 * <PercentageField name="weight" id="weight" value={10} onDecimalChange={handleDecimalChange} />
 */
const PercentageField: React.FC<
  Omit<TextFieldProps, 'value' | 'onChange'> & {
    initialDecimalValue?: number;
    onDecimalChange?: (value: number) => void;
    onPercentageChange?: (value: number) => void;
  }
> = ({
  initialDecimalValue,
  onDecimalChange,
  onPercentageChange,
  ...props
}) => {
  const { inputValue, handleInputValueChange, decimalValue, percentageValue } =
    usePercentage(initialDecimalValue);

  useEffect(() => {
    if (onDecimalChange) {
      onDecimalChange(decimalValue);
    }
  }, [decimalValue]);

  useEffect(() => {
    if (onPercentageChange) {
      onPercentageChange(percentageValue);
    }
  }, [percentageValue]);

  return (
    <TextField
      type="text"
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
      variant="outlined"
      size="small"
      value={inputValue}
      onChange={ev => handleInputValueChange(ev.currentTarget.value)}
      fullWidth
      {...props}
    />
  );
};

export default PercentageField;
