import React, { cloneElement, useState } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Grid,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { price } from '@common/number';
import { StyledTypography } from '@pages/EnterpriseAdmin/styles';
import CustomPagination from '@components/Pagination';
import {
  StyledTableRow,
  StyledTableCell,
  StyledDataCell,
  SearchBoxInput,
} from './styles';
import Loader from '@components/Loader';
import Impersonate from '@components/impersonate';
import InviteStatus from '@components/IntiveStatus/inviteStatus';
import { validArray } from '@common/validArray';
import { getIntegrationFullName } from '@common/string';

interface FirmDetailsProps {
  adminData: any;
  advisorData: any;
  enterpriseData: any;
}

const useStyles = makeStyles({
  root: {
    borderBottom: '1px solid #e4e7ec',
  },
  tableWrapper: {
    border: '1px solid #e4e7ec',
  },
});

const FirmDetailsTable = ({
  adminData,
  advisorData,
  enterpriseData,
}: FirmDetailsProps): React.JSX.Element => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const headers: any[] = [
    {
      key: 'Impersonate',
      value: 'impersonate',
      component: <Impersonate />,
      doFormat: true,
      width: '8%',
    },
    { key: 'Name', value: 'name', width: '10%' },
    { key: 'Emails', value: 'email', width: '10%' },
    { key: 'Total Clients', value: 'totalClients', width: '8%' },
    { key: 'Total AUMs', value: 'totalAum', width: '10%' },
    {
      key: 'Status',
      value: 'status',
      component: <InviteStatus />,
      doFormat: true,
      width: '8%',
    },
    { key: 'Last Login', value: 'lastLogin', width: '9%' },
    { key: 'Integrations', value: 'integrationDetails', width: '37%' },
  ];

  const TableCell = (header, row) => {
    switch (header.value) {
      case 'totalAum':
        return price(row[header.value]);
      case 'integrationDetails':
        return (
          <Box component={'span'}>
            {getIntegrationFullName(row, header) || '--'}
          </Box>
        );
      case 'lastLogin':
        return row[header?.value]?.substring(0, 10) || '--';
      case 'status':
        return cloneElement(header?.component, {
          status: row[header?.value],
          id: row['id'],
        });
      case 'impersonate':
        return cloneElement(header?.component, {
          status: row['status'],
          id: row['id'],
          userId: row['userId'],
          setIsLoading: setIsLoading,
        });
      default:
        return row[header?.value];
    }
  };
  const rowsPerPage = 10;

  const [adminPage, setAdminPage] = React.useState(1);
  const [advisorPage, setAdvisorPage] = React.useState(1);
  const [adminSearchTerm, setAdminSearchTerm] = React.useState('');
  const [advisorSearchTerm, setAdvisorSearchTerm] = React.useState('');

  const handleAdminSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdminSearchTerm(event.target.value);
  };

  const handleAdvisorSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdvisorSearchTerm(event.target.value);
  };

  const handleAdminPageChange = (newPage: number) => {
    setAdminPage(newPage);
  };

  const handleAdvisorPageChange = (newPage: number) => {
    setAdvisorPage(newPage);
  };

  const filteredAdminData = adminData?.filter(
    item =>
      item?.name?.toLowerCase().includes(adminSearchTerm.toLowerCase()) ||
      item?.email?.toLowerCase().includes(adminSearchTerm.toLowerCase()),
  );

  const filteredAdvisorData = advisorData?.filter(
    item =>
      item?.name?.toLowerCase().includes(advisorSearchTerm.toLowerCase()) ||
      item?.email?.toLowerCase().includes(advisorSearchTerm.toLowerCase()),
  );

  const adminStartIndex = (adminPage - 1) * rowsPerPage;
  const adminEndIndex = validArray(filteredAdminData)
    ? Math.min(adminStartIndex + rowsPerPage, filteredAdminData?.length)
    : 0;
  const adminDataOnCurrentPage = filteredAdminData?.slice(
    adminStartIndex,
    adminEndIndex,
  );

  const adminTotalCount = filteredAdminData?.length ?? 0;

  const advisorStartIndex = (advisorPage - 1) * rowsPerPage;
  const advisorEndIndex = Math.min(
    advisorStartIndex + rowsPerPage,
    filteredAdvisorData?.length,
  );
  const advisorDataOnCurrentPage = filteredAdvisorData?.slice(
    advisorStartIndex,
    advisorEndIndex,
  );

  const advisorTotalCount = filteredAdvisorData?.length ?? 0;
  if (isLoading) return <Loader />;
  return (
    <>
      <Grid container xs={12} mt={3} rowGap={1}>
        <Grid item container xs={12} sx={{ paddingBottom: '16px' }}>
          <Grid
            item
            container
            xs={12}
            justifyContent={'space-between'}
            mb={1.5}
          >
            <Grid
              item
              container
              xs={6}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <StyledTypography
                sx={{
                  paddingBottom: '0px',
                }}
              >
                Enterprise Administrator
              </StyledTypography>
            </Grid>
          </Grid>
          <Grid container xs={12} className={classes.tableWrapper} rowGap={2}>
            <Grid item container xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      {headers.map(header => (
                        <StyledTableCell
                          key={header.key}
                          sx={{
                            width: header.width,
                          }}
                        >
                          {header.key}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {validArray(enterpriseData) &&
                      enterpriseData.map((row, index) => (
                        <StyledTableRow
                          backgroundColor="white"
                          key={index}
                          className={classes.root}
                        >
                          {headers.map(header => (
                            <StyledDataCell key={header?.key}>
                              {TableCell(header, row)}
                            </StyledDataCell>
                          ))}
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid
            item
            container
            xs={12}
            justifyContent={'space-between'}
            mb={1.5}
          >
            <Grid
              item
              container
              xs={6}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <StyledTypography
                sx={{
                  paddingBottom: '0px',
                }}
              >
                Firm Administrator
              </StyledTypography>
            </Grid>

            <Grid
              item
              container
              xs={6}
              justifyContent={'flex-end'}
              alignItems={'center'}
            >
              <SearchBoxInput
                type="text"
                placeholder="Search"
                value={adminSearchTerm}
                onChange={handleAdminSearch}
                sx={{
                  marginRight: '0px',
                }}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} className={classes.tableWrapper} rowGap={2}>
            <Grid item container xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      {headers.map(header => (
                        <StyledTableCell
                          key={header.key}
                          sx={{
                            width: header.width,
                          }}
                        >
                          {header.key}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {validArray(adminDataOnCurrentPage) &&
                      adminDataOnCurrentPage.map((row, index) => (
                        <StyledTableRow
                          backgroundColor="white"
                          key={index}
                          className={classes.root}
                        >
                          {headers.map(header => (
                            <StyledDataCell key={header?.key}>
                              {TableCell(header, row)}
                            </StyledDataCell>
                          ))}
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
                <Grid item xs={12} p={'0px 15px 0px 15px'}>
                  <CustomPagination
                    page={adminPage}
                    count={adminTotalCount}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleAdminPageChange}
                  />
                </Grid>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container xs={12} mt={2}>
          <Grid
            item
            container
            xs={12}
            justifyContent={'space-between'}
            mb={1.5}
          >
            <Grid
              item
              container
              xs={6}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <StyledTypography
                sx={{
                  paddingBottom: '0px',
                }}
              >
                Firm Advisor
              </StyledTypography>
            </Grid>
            <Grid
              item
              container
              xs={6}
              justifyContent={'flex-end'}
              alignItems={'center'}
            >
              <SearchBoxInput
                type="text"
                placeholder="Search"
                value={advisorSearchTerm}
                onChange={handleAdvisorSearch}
                sx={{
                  marginRight: '0px',
                }}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} className={classes.tableWrapper}>
            <Grid item container xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      {headers.map(header => (
                        <StyledTableCell
                          key={header.key}
                          sx={{
                            width: header.width,
                          }}
                        >
                          {header.key}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {validArray(advisorDataOnCurrentPage) &&
                      advisorDataOnCurrentPage.map((row, index) => (
                        <StyledTableRow
                          key={index}
                          backgroundColor="white"
                          className={classes.root}
                        >
                          {headers.map(header => (
                            <StyledDataCell key={header?.key}>
                              {TableCell(header, row)}
                            </StyledDataCell>
                          ))}
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
                <Grid item xs={12} p={'0px 15px 0px 15px'}>
                  <CustomPagination
                    page={advisorPage}
                    count={advisorTotalCount}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleAdvisorPageChange}
                  />
                </Grid>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FirmDetailsTable;
