import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';

export type AiModule = {
  name: string;
  module: string;
  description: string;
  tools: {
    name: string;
    description: string;
    tool: string;
    enable: boolean;
  }[];
};

type UseGetAiModulesByIdArguments = {
  enterpriseId: string;
};

type UseGetAiModulesByIdResult = {
  aiModules: AiModule[];
  isLoadingAiModules: boolean;
};

type UseGetAiModulesByIdResponse = {
  data: {
    modules: AiModule[];
  };
};

export const useGetAiModulesById = ({
  enterpriseId,
}: UseGetAiModulesByIdArguments): UseGetAiModulesByIdResult => {
  const { fetchQuery } = useFetchQuery();

  const { data, isLoading } = useQuery<UseGetAiModulesByIdResponse>(
    ['aiModules', enterpriseId],
    fetchQuery({ path: `v2/chatbotModules/${enterpriseId}` }),
  );

  return {
    aiModules: data?.data.modules || [],
    isLoadingAiModules: isLoading,
  };
};
