import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';
import { Case } from './useGetCases';

type UseGetCaseByIdArguments = {
  id: string;
};

type UseGetCaseByIdResult = {
  caseData: Case | undefined;
  isCaseLoading: boolean;
};

type UseGetCaseByIdResponse = {
  data: Case;
};

export const useGetCaseById = ({
  id,
}: UseGetCaseByIdArguments): UseGetCaseByIdResult => {
  const { fetchQuery } = useFetchQuery();

  const { data, isLoading } = useQuery<UseGetCaseByIdResponse>(
    ['cases', id],
    fetchQuery({ path: `v2/ocios/cases/${id}` }),
    { enabled: !!id },
  );

  return {
    caseData: data?.data ? parseCase(data?.data) : undefined,
    isCaseLoading: isLoading,
  };
};

const parseCase = (caseData: Case) => ({
  ...caseData,
  milestones: caseData.milestones?.filter(
    milestone => milestone.actions.length > 0,
  ),
});
