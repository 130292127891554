import { useMutation } from 'react-query';
import { useFetchMutation } from '../../../helpers/api';

type UsePostCaseArguments = {
  advisorName: string;
  createdAt: string;
  description: string;
  name: string;
  secondaryOwner: string;
  successToastMessage: string;
  tier: string;
};

type UsePostCaseResult = {
  postCaseMutate: (data: UsePostCaseArguments) => void;
};

export const usePostCase = (): UsePostCaseResult => {
  const { fetchMutation } = useFetchMutation();

  const { mutate } = useMutation((data: UsePostCaseArguments) =>
    fetchMutation({
      path: `/v2/ocios/cases`,
      method: 'POST',
      body: {
        advisorName: data.advisorName,
        createdAt: data.createdAt,
        name: data.name,
        description: data.description,
        secondaryOwner: data.secondaryOwner,
        tier: data.tier,
      },
      invalidateQueries: [['cases']],
      successToastMessage: data.successToastMessage,
    }),
  );

  return { postCaseMutate: mutate };
};
