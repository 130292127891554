import { useMutation } from 'react-query';
import { useFetchMutation } from '../../../../helpers/api';

type UsePostFavoriteModelArguments = {
  modelId: string;
  successToastMessage: string;
};

type UsePostFavoriteModelMutationResult = {
  postFavoriteModelMutate: (data: UsePostFavoriteModelArguments) => void;
  postFavoriteModelLoading: boolean;
};

export const usePostMarketplaceFavoriteMutation =
  (): UsePostFavoriteModelMutationResult => {
    const { fetchMutation } = useFetchMutation();

    const { mutate, isLoading } = useMutation(
      (data: UsePostFavoriteModelArguments) =>
        fetchMutation({
          path: `/v2/models/favorites`,
          method: 'POST',
          body: { modelId: data.modelId },
          invalidateQueries: [['marketplaceModels'], ['sponsorFavoriteModel']],
          successToastMessage: data.successToastMessage,
        }),
    );

    return {
      postFavoriteModelMutate: mutate,
      postFavoriteModelLoading: isLoading,
    };
  };
