import React from 'react';
import moment from 'moment';
import { Grid, Typography } from '@mui/material';

type SummaryHeaderProps = {
  caseData: any;
  action: any;
};

const SummaryHeader = ({
  caseData,
  action,
}: SummaryHeaderProps): React.ReactNode => {
  return (
    <Grid item xs={6}>
      <Typography gutterBottom sx={{ fontSize: '14px', color: '#535256' }}>
        {caseData?.name}
      </Typography>
      <Typography gutterBottom sx={{ fontSize: '16px', fontWeight: '600' }}>
        {action?.versionName}
      </Typography>
      <Typography gutterBottom sx={{ fontSize: '12px', color: '#86858B' }}>
        Last Update {moment(action?.updatedAt).format('MM/DD/YYYY h:mm A')}
      </Typography>
    </Grid>
  );
};

export default SummaryHeader;
