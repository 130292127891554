import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
  Outlet,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';

// Helper
import ErrorScreen from 'app/helper/ErrorScreen';
import { NotFoundPage } from 'app/helper/NotFoundPage';
import { IsUserLoggedIn } from 'utils/common/authValidate';

// Selector
import { getAuthDetails } from '@features/Profile/selector';

// Slices
import { actions as profileActions } from '@features/Profile/slice';
import { actions as chatActions } from 'app/features/Chat/slice';

// Layout
import LayoutRoute from 'app/layout';
// Pages
// import InsightPage from '@pages/Insights';
import CIOViewpointsPage from '@pages/CIOViewpoints';
import Login from '@pages/Login';
// Components
import ToastAlert from '@components/ToastAlert';
// Styles
import GlobalStyle from '@styles/global';
import { AppContainer } from './styles';
import MyProfile from 'app/pages/Profile';
// import Drawer from '@components/Drawer';
import IntegrationPage from '@pages/IntegrationPage';
import ChatView from '@pages/Chat/ChatViewIndex';
import TestResults from '@pages/TestResults/TestResultList';
import Tests from '@pages/Tests';
import SuggestedActions from '@pages/SuggestedActions';
import EnterpriseDashboardV2 from '@pages/EnterpriseDashboardV2';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { oktaAuth } from '@services/oktaAuth';
import SuperAdminPage from '@pages/SuperAdminPage';
import EnterpriseAdmin from '@pages/EnterpriseAdmin';
import FirmAdmin from '@pages/FirmAdmin';
import TermsAndCondition from '@pages/TermsAndCondition';
import Signup from '@pages/Signup';
import ClientsV2 from '@pages/ClientsV2';
import CurrentEvents from '@pages/CurrentEvents';
import Questions from '@pages/Questions';
import ModelsPage from 'pages/Models';
import CasesPage from 'pages/Cases';
import SettingsPage from 'pages/Settings';
import Proposals from '@pages/Proposals';
import LoginCallback from '../pages/Login/Callback';
import PortfolioConstruction from 'pages/Cases/PortfolioConstruction';
import { useRoles } from '../helpers/hooks/useRoles';

const LayoutDelayTransitionTime = 1000;

const navigationOnClient = () => {
  const navigate = useNavigate();
  (window as any).appNavigate = path => navigate(path);
};

function App(): React.JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = location;

  const isChatStreaming = localStorage.getItem('streaming') as string;
  const isLoggedIn: boolean = IsUserLoggedIn({
    apiToken: localStorage.getItem('x-tifin-ai-token') as string,
  });

  // Expose the navigation function globally for Pendo.ai guides
  navigationOnClient();

  const { getProfileMenuItems } = useRoles();
  const HeaderMenuList: any[] = getProfileMenuItems();
  const profileState: any = useSelector(getAuthDetails);
  const advisorDetails = useSelector(getAuthDetails);
  const [previousPath, setPreviousPath] = useState<string | null>(null);
  const [NavState, setNavState] = useState<string | null>(null);

  useEffect(() => {
    const token = localStorage.getItem('x-tifin-ai-token') as string;
    if (!advisorDetails?.email && token && isLoggedIn) {
      dispatch(
        profileActions.userInfoRequest({
          token,
        }),
      );
    }
    if (advisorDetails?.oktaUid) {
      window.pendo.initialize({
        visitor: {
          id: advisorDetails.advisorId,
          email: advisorDetails.email,
          firstName: advisorDetails.firstName,
          lastName: advisorDetails.lastName,
        },
      });
    }
  }, [advisorDetails, window]);

  useEffect(() => {
    // Save the previous path in state whenever the location changes
    if (previousPath !== pathname) {
      const PrevIndex: number = HeaderMenuList.findIndex(
        (f: any) => f.path === previousPath,
      );
      const NextIndex: number = HeaderMenuList.findIndex(
        (f: any) => f.path === pathname,
      );
      const navState: string = PrevIndex > NextIndex ? 'Prev' : 'Next';
      setNavState(navState);
      setPreviousPath(pathname);
    }
  }, [pathname]);

  const customAuthHandler = () => {
    navigate('/login');
  };

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri?: string,
  ) => {
    navigate(toRelativeUrl(originalUri || '', window.location.origin), {
      replace: true,
    });
  };

  useEffect(() => {
    if (profileState.advisorId && isChatStreaming) {
      dispatch(
        chatActions.initiateEventStream({ advisorId: profileState.advisorId }),
      );
    }
    const removeStream = () => dispatch(chatActions.clearEventStream());

    // just to be sure we are closing the connection once we reload the page
    window.addEventListener('beforeunload', removeStream);

    return () => {
      removeStream();
      window.removeEventListener('beforeunload', removeStream);
    };
  }, [isChatStreaming, profileState.advisorId]);

  return (
    // Revisit this - inline sx
    <AppContainer className="appContainer" sx={{ height: '100vh' }}>
      <GlobalStyle />
      <Sentry.ErrorBoundary
        fallback={
          <ErrorScreen
            title="An unknown error has occurred"
            message="Please try again in some time."
          />
        }
      >
        <ToastAlert />
        <Security
          oktaAuth={oktaAuth}
          restoreOriginalUri={restoreOriginalUri}
          onAuthRequired={customAuthHandler}
        >
          <Routes>
            <Route path="/signup" Component={Signup} />
            <Route path="/login" Component={Login} />
            <Route path="/login/callback" Component={LoginCallback} />

            <Route
              path="/"
              element={
                <LayoutRoute
                  animate={false}
                  isLoggedIn={isLoggedIn}
                  navState={NavState}
                  delay={isLoggedIn ? LayoutDelayTransitionTime : 0}
                >
                  <Outlet />
                </LayoutRoute>
              }
            >
              <Route path="/" element={<Navigate to="/conversations" />} />
              <Route
                path="/authorize/wealthbox"
                element={<IntegrationPage />}
              />
              <Route
                path="/authorize/salesforce"
                element={<IntegrationPage />}
              />
              <Route
                path="/authorize/wealthbox/"
                element={<IntegrationPage />}
              />
              <Route
                path="/authorize/salesforce/"
                element={<IntegrationPage />}
              />
              <Route path="/authorize/orion/" element={<IntegrationPage />} />
              <Route path="/authorize/hubspot/" element={<IntegrationPage />} />

              {/* <Route path="/insights" element={<InsightPage />} /> */}
              <Route path="/view-points" element={<CIOViewpointsPage />} />
              <Route path="/conversations/*" element={<ChatView />} />
              <Route path="/actions" element={<SuggestedActions />} />
              <Route path="/clients" element={<ClientsV2 />} />
              <Route path="/profile" element={<MyProfile />} />
              <Route path="/integrations" element={<IntegrationPage />} />
              <Route path="/test/queries" element={<Tests />} />
              <Route path="/test/results" element={<TestResults />} />
              <Route path="/admin-console" element={<SuperAdminPage />} />
              <Route path="/current-events" element={<CurrentEvents />} />
              <Route path="/firms" element={<EnterpriseAdmin />} />
              <Route path="/manage-firms" element={<FirmAdmin />} />
              <Route path="/terms-condition" element={<TermsAndCondition />} />
              <Route
                path="/viewpoints_dashboard"
                element={<EnterpriseDashboardV2 />}
              />
              <Route path="/questions" element={<Questions />} />
              <Route path="/proposals" element={<Proposals />} />
              <Route path="/settings/*" element={<SettingsPage />} />
              <Route path="/models/*" element={<ModelsPage />} />
              <Route path="/cases/*" element={<CasesPage />} />
              <Route
                path="/portfolio-constructions/*"
                element={<PortfolioConstruction />}
              />
            </Route>
            <Route path="/404" Component={NotFoundPage} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Security>
      </Sentry.ErrorBoundary>
      {/* <BGOverlay /> */}
    </AppContainer>
  );
}

export default App;
