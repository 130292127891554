import React from 'react';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import { Stack, IconButton, inputBaseClasses } from '@mui/material';
import DeleteIcon from '../../../../../../static/icons/Delete';
import { Model } from 'pages/Models/types';
import PercentageField from 'components/PercentageField';

interface TickerTableProps {
  holdings: Model['holdings'];
  handleDeleteHolding: (id: number) => void;
  handleEditHoldingWeight: (id: number, weight: number) => void;
}

const TickerTable: React.FC<TickerTableProps> = ({
  holdings,
  handleDeleteHolding,
  handleEditHoldingWeight,
}) => {
  const ActionsCell: React.FC = (holdind: any) => {
    const handleDelete = () => {
      handleDeleteHolding(holdind.id);
    };

    return (
      <Stack direction="row" gap={1} alignItems="center">
        <IconButton
          aria-label="delete"
          size="small"
          onClick={handleDelete}
          sx={{ color: '#4F4F4F' }}
        >
          <DeleteIcon width={16} height={16} />
        </IconButton>
      </Stack>
    );
  };

  const EditCell: React.FC = (holdind: any) => {
    const handleDecimalChange = (value: number) => {
      handleEditHoldingWeight(holdind.id, value);
    };

    return (
      <Stack direction="row" gap={1} alignItems="center">
        <PercentageField
          initialDecimalValue={holdind.value}
          onDecimalChange={handleDecimalChange}
          sx={{
            [`& .${inputBaseClasses.input}`]: {
              padding: '5px',
            },
          }}
        />
      </Stack>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Security',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'weight',
      headerName: 'Weight',
      display: 'flex',
      width: 130,
      type: 'number',
      headerAlign: 'left',
      renderCell: EditCell,
    },
    {
      field: 'actions',
      headerName: '',
      display: 'flex',
      renderCell: ActionsCell,
      width: 20,
    },
  ];

  return (
    <DataGrid
      rows={holdings.map(holding => ({
        id: holding.id,
        name: holding.instrument.name,
        weight: holding.weight,
      }))}
      columns={columns}
      disableColumnMenu
      disableRowSelectionOnClick
      disableColumnSelector
      disableMultipleRowSelection
      disableColumnResize={true}
      hideFooter
      sx={{
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
          {
            outline: 'none',
          },
      }}
    />
  );
};

export default TickerTable;
