import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AllCases from './AllCases';
import Summary from './PortfolioConstruction/Summary';
import TabsLayout from '../../components/TabsLayout/TabsLayout';
import ModelSets from './PortfolioConstruction/ModelSets';

const CasesPage: React.FC = () => {
  const tabs = [
    {
      label: 'All Cases',
      path: 'all-cases',
      validPatterns: ['/cases/all-cases'],
    },
    {
      label: 'My Cases',
      path: 'my-cases',
      validPatterns: ['/cases/my-cases'],
    },
  ];

  return (
    <Routes>
      <Route element={<TabsLayout tabs={tabs} />}>
        <Route index element={<Navigate to="all-cases" replace />} />
        <Route path="/all-cases" element={<AllCases />} />
        <Route path="/my-cases" element={<AllCases myCasesFilter={true} />} />
      </Route>
      <Route
        path="/:caseId/portfolio-constructions/:actionId/summary"
        element={<Summary />}
      />
      <Route
        path="/:caseId/portfolio-constructions/:actionId/model-sets/:modelSetName"
        element={<ModelSets />}
      />
    </Routes>
  );
};

export default CasesPage;
