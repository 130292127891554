import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';

type RecentPortfolioConstruction = {
  id: string; // case id
  name: string;
  owners: string[];
  versionName: string;
  updatedAt: number;
  actionId: string;
};

type UseGetRecentPortfolioConstructionArguments = {
  searchFilter: string;
};

type UseGetRecentPortfolioConstructionReturn = {
  recentPortfolioConstructions: RecentPortfolioConstruction[];
  isLoadingRecentPortfolioConstructions: boolean;
};

type UseGetRecentPortfolioConstructionResponse = {
  data: RecentPortfolioConstruction[];
};

export const useGetRecentPortfolioConstructions = ({
  searchFilter,
}: UseGetRecentPortfolioConstructionArguments): UseGetRecentPortfolioConstructionReturn => {
  const { fetchQuery } = useFetchQuery();

  const { data, isLoading } =
    useQuery<UseGetRecentPortfolioConstructionResponse>(
      ['recentPortfolioConstructions', searchFilter],
      fetchQuery({ path: `v2/ocios/cases/lastUpdated?search=${searchFilter}` }),
    );

  return {
    recentPortfolioConstructions: data?.data || [],
    isLoadingRecentPortfolioConstructions: isLoading,
  };
};
