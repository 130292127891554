/* eslint-disable no-useless-escape */
import {
  ForumOutlined as ForumOutlinedIcon,
  GridViewOutlined as GridViewOutlinedIcon,
  Settings as SettingsIcon,
  StreamOutlined as StreamOutlinedIcon,
} from '@mui/icons-material';
import ModelsIcon from '@icons/ModelsIcon';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Logout } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSelector } from 'react-redux';
import { getAuthDetails } from '@features/Profile/selector';
import { AuthDetailsProps } from '@features/Profile/types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IsUserLoggedIn } from '@common/authValidate';

type UseRolesReturn = {
  getMainMenuItems: () => MainRoutes[];
  getProfileMenuItems: () => MainRoutes[];
  isOcioRole: () => boolean;
  isUserRole: (role: string) => boolean;
  profileState: any;
};

type MainRoutes = {
  name: string;
  enableNotifications: boolean;
  notification: boolean;
  path: string;
  cursor: string;
  showToUser: string[];
  children: any[];
  Icon: any;
  mainMenu: boolean;
};

const roles = {
  FIRM_ADMIN: 'FIRM_ADMIN',
  ADVISOR: 'ADVISOR',
  ENTERPRISE_ADMIN: 'ENTERPRISE_ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
  OCIO_ADMIN: 'OCIO_ADMIN',
  OCIO_USER: 'OCIO_USER',
  LOGIN: 'LOGIN',
};

const mainRoutes: MainRoutes[] = [
  {
    name: 'Home',
    enableNotifications: true,
    notification: true,
    path: '/conversations/stream',
    cursor: 'pointer',
    showToUser: [
      roles.FIRM_ADMIN,
      roles.ADVISOR,
      roles.OCIO_ADMIN,
      roles.OCIO_USER,
    ],
    children: [
      {
        name: 'Conversations',
        enableNotifications: true,
        notification: true,
        path: '/start',
        cursor: 'pointer',
        Icon: ForumOutlinedIcon,
      },
    ],
    Icon: ForumOutlinedIcon,
    mainMenu: true,
  },
  {
    name: 'My Firms',
    enableNotifications: false,
    notification: false,
    path: '/firms',
    cursor: 'pointer',
    children: [],
    Icon: AccountBalanceIcon,
    showToUser: [roles.ENTERPRISE_ADMIN],
    mainMenu: true,
  },
  {
    name: 'Insights & Viewpoints',
    enableNotifications: false,
    notification: false,
    path: '/viewpoints_dashboard',
    cursor: 'pointer',
    children: [],
    Icon: GridViewOutlinedIcon,
    showToUser: [roles.ENTERPRISE_ADMIN],
    mainMenu: true,
  },
  {
    name: 'Configurations',
    enableNotifications: false,
    notification: false,
    path: '/questions',
    cursor: 'pointer',
    children: [],
    Icon: SettingsIcon,
    showToUser: [roles.ENTERPRISE_ADMIN],
    mainMenu: true,
  },
  {
    name: 'Markets',
    enableNotifications: false,
    notification: false,
    path: '/actions',
    cursor: 'pointer',
    children: [],
    Icon: StreamOutlinedIcon,
    showToUser: [roles.FIRM_ADMIN, roles.ADVISOR],
    mainMenu: true,
  },
  {
    name: 'Firm Management',
    enableNotifications: false,
    notification: false,
    path: '/manage-firms',
    cursor: 'pointer',
    children: [],
    Icon: AccountBalanceIcon,
    showToUser: [roles.FIRM_ADMIN],
    mainMenu: true,
  },
  {
    name: 'Clients',
    enableNotifications: false,
    notification: false,
    path: '/clients',
    cursor: 'pointer',
    children: [],
    Icon: GroupsOutlinedIcon,
    showToUser: [roles.FIRM_ADMIN, roles.ADVISOR],
    mainMenu: true,
  },
  {
    name: 'Cases',
    enableNotifications: false,
    notification: false,
    path: '/cases',
    cursor: 'pointer',
    children: [],
    Icon: GroupsOutlinedIcon,
    showToUser: [roles.OCIO_ADMIN, roles.OCIO_USER],
    mainMenu: true,
  },
  {
    name: 'Construction',
    enableNotifications: false,
    notification: false,
    path: '/portfolio-constructions',
    cursor: 'pointer',
    children: [],
    Icon: StreamOutlinedIcon,
    showToUser: [roles.OCIO_ADMIN, roles.OCIO_USER],
    mainMenu: true,
  },
  {
    name: 'Models',
    enableNotifications: false,
    notification: false,
    path: '/models',
    cursor: 'pointer',
    children: [],
    Icon: ModelsIcon,
    showToUser: [
      roles.FIRM_ADMIN,
      roles.ADVISOR,
      roles.OCIO_ADMIN,
      roles.OCIO_USER,
    ],
    mainMenu: true,
  },
  {
    name: 'Settings',
    enableNotifications: false,
    notification: false,
    path: '/settings/profile',
    cursor: 'pointer',
    children: [],
    Icon: SettingsOutlinedIcon,
    showToUser: [roles.FIRM_ADMIN, roles.ADVISOR],
    mainMenu: true,
  },
  {
    name: 'Tests',
    enableNotifications: false,
    notification: false,
    path: '/test/queries',
    cursor: 'pointer',
    children: [],
    Icon: StreamOutlinedIcon,
    showToUser: [roles.SUPER_ADMIN],
    mainMenu: true,
  },
  {
    name: 'Test Results',
    enableNotifications: false,
    notification: false,
    path: '/test/results',
    cursor: 'pointer',
    Icon: StreamOutlinedIcon,
    children: [],
    showToUser: [roles.SUPER_ADMIN],
    mainMenu: true,
  },
  {
    name: 'Administrator Console',
    enableNotifications: false,
    notification: false,
    path: '/admin-console',
    cursor: 'pointer',
    Icon: StreamOutlinedIcon,
    children: [],
    showToUser: [roles.SUPER_ADMIN],
    mainMenu: true,
  },
  {
    name: 'Current Events',
    enableNotifications: false,
    notification: false,
    path: '/current-events',
    cursor: 'pointer',
    Icon: StreamOutlinedIcon,
    children: [],
    showToUser: [roles.SUPER_ADMIN],
    mainMenu: true,
  },
  {
    name: 'Profile',
    enableNotifications: false,
    notification: false,
    path: '/profile',
    cursor: 'pointer',
    showToUser: [
      roles.FIRM_ADMIN,
      roles.ADVISOR,
      roles.ENTERPRISE_ADMIN,
      roles.SUPER_ADMIN,
      roles.OCIO_ADMIN,
      roles.OCIO_USER,
    ],
    children: [],
    Icon: AccountCircleIcon,
    mainMenu: false,
  },
  {
    name: 'Logout',
    enableNotifications: false,
    notification: false,
    path: '/logout',
    cursor: 'pointer',
    showToUser: [
      roles.FIRM_ADMIN,
      roles.ADVISOR,
      roles.ENTERPRISE_ADMIN,
      roles.SUPER_ADMIN,
      roles.OCIO_ADMIN,
      roles.OCIO_USER,
    ],
    children: [],
    Icon: Logout,
    mainMenu: false,
  },
  {
    name: 'Exit Impersonation',
    enableNotifications: false,
    notification: false,
    path: '/exit-impersonation',
    cursor: 'pointer',
    showToUser: [
      roles.FIRM_ADMIN,
      roles.ADVISOR,
      roles.ENTERPRISE_ADMIN,
      roles.SUPER_ADMIN,
      roles.OCIO_ADMIN,
      roles.OCIO_USER,
    ],
    Icon: Logout,
    children: [],
    mainMenu: false,
  },
];

const landingRoutes = {
  ADVISOR: {
    landingRoute: '/conversations',
  },
  FIRM_ADMIN: {
    landingRoute: '/manage-firms',
  },
  SUPER_ADMIN: {
    landingRoute: '/admin-console',
  },
  ENTERPRISE_ADMIN: {
    landingRoute: '/firms',
  },
  OCIO_ADMIN: {
    landingRoute: '/conversations',
  },
  OCIO_USER: {
    landingRoute: '/conversations',
  },
};

export const useRoles = (): UseRolesReturn => {
  const profileState: AuthDetailsProps = useSelector(getAuthDetails);
  const navigate = useNavigate();
  const currentPath = useLocation().pathname;

  const isLoggedIn: boolean = IsUserLoggedIn({
    apiToken: localStorage.getItem('x-tifin-ai-token') as string,
  });

  useEffect(() => {
    const ensureRoleRoutes = (): void => {
      if (
        !isLoggedIn &&
        currentPath !== '/login' &&
        currentPath !== '/signup'
      ) {
        navigate('/login');
        return;
      }

      if (isLoggedIn && profileState.role === '') {
        return;
      }

      if (isLoggedIn && !profileState.termsAndConditionsAcceptedAt) {
        return navigate('/terms-condition');
      }

      const hasAccess =
        isLoggedIn &&
        mainRoutes.some(route => {
          return (
            route.path === currentPath ||
            (route.path.split('/')[1] === currentPath.split('/')[1] &&
              route.showToUser.includes(profileState.role))
          );
        });

      const landingRoute = landingRoutes[profileState.role]?.landingRoute;

      if (!hasAccess && isLoggedIn) {
        navigate(landingRoute);
      }
    };

    ensureRoleRoutes();
  }, [profileState, isLoggedIn, navigate]);

  const getMainMenuItems = (): MainRoutes[] => {
    return mainRoutes.filter(
      route => route.showToUser.includes(profileState.role) && route.mainMenu,
    );
  };

  const getProfileMenuItems = (): MainRoutes[] => {
    return mainRoutes.filter(
      route => route.showToUser.includes(profileState.role) && !route.mainMenu,
    );
  };

  const isOcioRole = (): boolean => {
    return (
      profileState.role === roles.OCIO_ADMIN ||
      profileState.role === roles.OCIO_USER
    );
  };

  const isUserRole = (role: string): boolean => profileState.role === role;

  return {
    getMainMenuItems,
    getProfileMenuItems,
    isOcioRole,
    isUserRole,
    profileState,
  };
};
