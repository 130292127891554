import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

type SelectComponentProps = {
  id: string;
  name: string;
  value: string;
  options: {
    key: string;
    label: string;
  }[];
  handleChange: (key: string, value: string) => void;
};

const SelectComponent: React.FC<SelectComponentProps> = ({
  id,
  options,
  name,
  value = '',
  handleChange,
}) => {
  return (
    <FormControl fullWidth>
      <Select
        id={id}
        name={name}
        value={value}
        size="small"
        onChange={({ target }) => handleChange(name, target.value)}
      >
        {options.map(option => (
          <MenuItem key={option.key} value={option.key}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectComponent;
