import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, InputLabel, styled } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

interface BackButtonProps {
  name: string;
  handleClickBack?: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ name, handleClickBack }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (handleClickBack) {
      handleClickBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <BackButtonStyled onClick={handleClick} startIcon={<ArrowBackIosNewIcon />}>
      <BackTitle sx={{ textTransform: 'none' }}>{name}</BackTitle>
    </BackButtonStyled>
  );
};

export default BackButton;

const BackButtonStyled = styled(Button)`
  align-items: center;
  margin: 0;
  gap: 4px;
  text-decoration: none;
  text-transform: none;
  color: #343330;
  cursor: pointer;
`;

const BackTitle = styled(InputLabel)`
  color: #4f4f4f;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  line-height: 19px;
  cursor: pointer;
`;
