import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';

export type CasesOrderBy = 'createdAt' | 'updatedAt';

export type CasesOrder = 'asc' | 'desc';

export type Case = {
  advisorName: string;
  id: string;
  description: string;
  name: string;
  owners: string;
  createdAt: string;
  stage: string;
  status: string;
  tier: string;
  updatedAt: string;
  milestones?: CaseMilestone[];
};

export type CaseMilestone = {
  id: string;
  enterpriseId: string;
  displayName: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  actions: CaseMilestoneAction[];
};

export type CaseMilestoneAction = {
  id: string;
  caseId: string;
  userId: string;
  milestoneId: string;
  versionName: string;
  createdAt: string;
  updatedAt: string;
  draft: boolean;
  value?: any;
};

type UseGetCasesArguments = {
  currentPage: number;
  myCasesFilter: boolean;
  order: string;
  orderBy: string;
  pageSize: number;
  searchFilter: string;
  statusFilter: string;
};

type UseGetCasesResult = {
  cases: Case[];
  casesStatusCount: {
    complete: number;
    inProgress: number;
  };
  casesCurrentPage: number;
  casesTotalCount: number;
  casesTotalPages: number;
  isLoadingCases: boolean;
};

type useGetCasesResponse = {
  data: Case[];
  statusCount: {
    complete: number;
    inProgress: number;
  };
  currentPage: number;
  totalCount: number;
  totalPages: number;
};

export const useGetCases = ({
  myCasesFilter,
  statusFilter,
  searchFilter,
  currentPage,
  order,
  orderBy,
  pageSize,
}: UseGetCasesArguments): UseGetCasesResult => {
  const { fetchQuery } = useFetchQuery();

  const queryParams = new URLSearchParams();
  queryParams.set('page', currentPage.toString());
  queryParams.set('myCases', myCasesFilter.toString());
  queryParams.set('order', order);
  queryParams.set('orderBy', orderBy);
  queryParams.set('limit', pageSize.toString());
  queryParams.set('search', searchFilter);
  if (statusFilter) {
    queryParams.set('status', statusFilter);
  }

  const { data, isLoading } = useQuery<useGetCasesResponse>(
    [
      'cases',
      currentPage,
      myCasesFilter,
      order,
      orderBy,
      pageSize,
      searchFilter,
      statusFilter,
    ],
    fetchQuery({ path: `v2/ocios/cases?${queryParams.toString()}` }),
  );

  const cases = {
    cases: data?.data || [],
    casesStatusCount: {
      complete: data?.statusCount?.complete || 0,
      inProgress: data?.statusCount?.inProgress || 0,
    },
    casesCurrentPage: data?.currentPage || 1,
    casesTotalCount: data?.totalCount || 0,
    casesTotalPages: data?.totalPages || 0,
  };

  return {
    ...cases,
    isLoadingCases: isLoading,
  };
};
