import { useDispatch } from 'react-redux';
import { oktaAuth } from '@services/oktaAuth';
import { actions as chatActions } from 'app/features/Chat/slice';

type UseLogoutReturn = {
  handleLogout: () => void;
  handleRemoveImpersonation: () => void;
};

const useLogout = (): UseLogoutReturn => {
  const dispatch = useDispatch();

  const handleLogout = async () => {
    dispatch(chatActions.clearEventStream());

    try {
      oktaAuth.signOut();
      localStorage.clear();
    } catch (error) {
      console.error('Sign out error:', error);
    }
  };

  const handleRemoveImpersonation = async () => {
    localStorage.removeItem('impersonation-id');
    location.href = '/';
  };
  return { handleLogout, handleRemoveImpersonation };
};

export default useLogout;
