import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';
import IconDotLine from '@images/icon-dot-line.svg';
import IconDotPlot from '@images/icon-dot-plot.svg';
import IconPeople from '@images/icon-people.svg';

export type EnterpriseQuestions = {
  title: string;
  icon: string;
  questions: EnterpriseQuestion[];
};

export type EnterpriseQuestion = {
  text: string;
  prompt: string;
  navigate: string;
};

type useGetEnterpriseQuestionsReturn = {
  questions: EnterpriseQuestions[];
  isLoaded: boolean;
  findQuestionByText: (question: string) => EnterpriseQuestion | undefined;
};

export function useGetEnterpriseQuestions(): useGetEnterpriseQuestionsReturn {
  const { fetchQuery } = useFetchQuery();

  const { data } = useQuery(
    ['enterpriseQuestions'],
    fetchQuery({ path: '/enterprise/enterpriseQuestions' }),
    {
      select: (res: any) =>
        res.data
          // clean categories
          .map(category => ({
            category: category?.category,
            questions: category?.question.map(parseQuestion),
          }))
          // ignore categories with no questions
          .filter(category => category.questions.length > 0)
          // map to the format we need
          .map(({ category, questions }, index) => ({
            questions,
            title: category,
            icon: categoriesIcons[index % categoriesIcons.length],
          })) || [],
    },
  );

  const findQuestionByText = (question: string) => {
    return data
      .map(({ questions }) => questions)
      .flat()
      .find(({ text }) => text === question);
  };

  return {
    questions: data || [],
    isLoaded: !!data,
    findQuestionByText,
  };
}
const parseQuestion = (question: string) => {
  const questionParts = question.split('|');

  // text
  const text = questionParts[0];

  // prompt
  const promptPart = questionParts.find(part => part.startsWith('Prompt='));
  const prompt = promptPart?.replace('Prompt=', '') || text;

  // navigate
  const navigatePart = questionParts.find(part => part.startsWith('Navigate='));
  const navigate = navigatePart?.replace('Navigate=', '') || '';

  return { text, prompt, navigate };
};

const categoriesIcons = [IconPeople, IconDotPlot, IconDotLine];
